import AccountApiService from './account-api-service';

import { actions } from './account-reducer';
import { setLive } from '../live/live-actions';
import { State } from '..';

const accountApiService = new AccountApiService();

const getAccountId = (state: State) => state.account.details?.id;

export const getAccount = (
  tokenOrId: string,
  onSuccess?: any,
) => async dispatch => {
  dispatch(actions.getAccount());

  try {
    const response = await accountApiService.getAccount(tokenOrId);
    dispatch(actions.getAccountSuccess(response.data.items[0]));

    if (onSuccess) dispatch(onSuccess());
  } catch (e) {
    dispatch(actions.getAccountError(e));
  }
};

export const getAllAccountInformation = () => async (dispatch, getState) => {
  dispatch(actions.getAllAccountInformation());

  try {
    const accountId = getAccountId(getState());
    const info = await accountApiService.getAllAccountInformation(accountId);
    dispatch(actions.getAllAccountInformationSuccess(info));
  } catch (e) {
    dispatch(actions.getAllAccountInformationError(e));
  }
};

export const getPaymentMethods = () => async (dispatch, getState) => {
  dispatch(actions.getPaymentMethods());

  try {
    const accountId = getAccountId(getState());
    const response = await accountApiService.getPaymentMethods(accountId);
    dispatch(actions.getPaymentMethodsSuccess(response.data));
  } catch (e) {
    dispatch(actions.getPaymentMethodsError());
  }
};

export const addPaymentMethod = (stripeToken: string) => async (
  dispatch,
  getState,
) => {
  dispatch(actions.addPaymentMethod());

  try {
    const accountId = getAccountId(getState());
    await accountApiService.addPaymentMethod(accountId, stripeToken);
    dispatch(actions.addPaymentMethodSuccess());
    dispatch(getPaymentMethods());
  } catch (e) {
    dispatch(actions.addPaymentMethodError());
  }
};

export const deletePaymentMethod = (cardId: string) => async (
  dispatch,
  getState,
) => {
  dispatch(actions.deletePaymentMethod());

  try {
    const accountId = getAccountId(getState());
    await accountApiService.deletePaymentMethod(accountId, cardId);
    dispatch(actions.deletePaymentMethodSuccess());
    dispatch(getPaymentMethods());
  } catch (e) {
    dispatch(actions.deletePaymentMethodError());
  }
};

export const setDefaultPaymentMethod = (cardId: string) => async (
  dispatch,
  getState,
) => {
  dispatch(actions.setDefaultPaymentMethod());

  try {
    const accountId = getAccountId(getState());
    await accountApiService.setDefaultPaymentMethod(accountId, cardId);
    dispatch(actions.setDefaultPaymentMethodSuccess());
    dispatch(getPaymentMethods());
  } catch (e) {
    dispatch(actions.setDefaultPaymentMethodError());
  }
};

export const enableAmex = () => async (dispatch, getState) => {
  dispatch(actions.enableAmex());

  try {
    const accountId = getAccountId(getState());
    await accountApiService.enableAmex(accountId);
    dispatch(actions.enableAmexSuccess());
    dispatch(getAccount(accountId));
  } catch (e) {
    dispatch(actions.enableAmexFailure());
  }
};

export const goLive = (data: any) => async (dispatch, getState) => {
  dispatch(actions.goLive());

  try {
    const accountId = getAccountId(getState());
    await accountApiService.goLive(accountId, data);

    dispatch(actions.goLiveSuccess());
    dispatch(getAccount(accountId, () => setLive(true)));
  } catch (error) {
    dispatch(actions.goLiveError());
  }
};

export const { clear } = actions;
