import http from '../services/http-service';
import config from '../utils/config';
import { CardsOptions } from './cards-types';

export default class CardsApiService {
  private buildParams(params: any, { last, from, to, limit }: CardsOptions) {
    if (from && to) {
      params.from = from;
      params.to = to;
    }
    if (last) params.start = JSON.stringify(last);
    if (limit) params.limit = limit;
  }

  private rawGetCardsCount({ programId, last, from, to, limit }: CardsOptions) {
    const url = `programs/${programId}/cards`;
    const params: any = {
      select: 'count',
    };

    this.buildParams(params, { last, from, to, limit } as CardsOptions);

    return http.get(url, { params });
  }

  public getCards({ programId, last, from, to, limit }: CardsOptions) {
    const url = `programs/${programId}/cards`;
    const params: any = {
      projection: 'lastNumbers,expDate,scheme,created,id',
    };

    this.buildParams(params, { last, from, to, limit } as CardsOptions);

    return http.get(url, { params });
  }

  public async getAllCards(options: CardsOptions) {
    let response = await this.getCards(options);
    let allCards = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getCards({
        ...options,
        last: response.data.last,
      });

      allCards = [...allCards, ...response.data.items];
    }

    return allCards;
  }

  public async getCardsCount(cardsOptions: CardsOptions) {
    let response = await this.rawGetCardsCount(cardsOptions);
    let { count } = response.data;

    while (response.data.last && count < config.MAX_CARDS_COUNT) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.rawGetCardsCount({
        ...cardsOptions,
        last: response.data.last,
      });
      count += response.data.count;
    }

    return count;
  }
}
