import translation from '../services/i18n-service';

export enum OfferType {
  ALLSET = 'allSet',
  EXPIRED = 'expired',
  LIVE = 'live',
  PENDING = 'pending',
}

export enum PaymentType {
  AUTOMATIC = 'credit_card',
  MANUAL = 'bank_transfer',
}

export interface DiscountType {
  name: string;
  value: number;
}

export interface RejectInfo {
  code: number;
  message: string;
}

export interface PaymentInfo {
  type: PaymentType;
  stripeToken?: string;
  email?: string;
}

type AccountType = { type: 'merchant' | 'card-linking'; id: string };

export interface Offer {
  id: string | undefined;
  accepted: boolean;
  activation: { enabled: boolean; qualifiedTransactionsLimit?: number };
  brandId: string | undefined;
  brandLogoURL: string | undefined;
  brandName: string | undefined;
  userId: string | undefined;
  type: DiscountType;
  name: string;
  daysOfWeek: number[];
  countryCode: string;
  currency: string;
  publisherId: string;
  created: string;
  updated: string;
  startDate: string;
  endDate: string;
  maxTransactionAmount: number;
  minTransactionAmount: number;
  performanceFee: number;
  returnPeriod: number;
  additionalTerms: string;
  schemes: string[] | undefined;
  funded: AccountType;
  origin: AccountType;
  locationsTotal: number;
}
export function transformToOffer(data: any): Offer {
  return {
    id: data.id,
    accepted: data.accepted,
    activation: data.activation,
    brandId: data.brandId,
    brandLogoURL: data.brandLogoURL,
    brandName: data.brandName,
    userId: data.userId,
    type: data.type,
    name: data.name,
    daysOfWeek: data.daysOfWeek || [0, 1, 2, 3, 4, 5, 6],
    countryCode: data.countryCode,
    currency: data.currency,
    publisherId: data.publisherId,
    created: data.created,
    updated: data.updated,
    startDate: data.startDate,
    endDate: data.endDate,
    maxTransactionAmount: data.maxTransactionAmount,
    minTransactionAmount: data.minTransactionAmount,
    performanceFee: data.performanceFee,
    returnPeriod: data.returnPeriod,
    additionalTerms: data.additionalTerms,
    schemes: data.schemes,
    funded: data.funded,
    origin: data.origin,
    locationsTotal: data.locationsTotal,
  };
}

export interface OfferOptions {
  endDateAfterOrNull?: string;
  endDateBefore?: string;
  last?: any;
  qualifying?: boolean;
  startDateAfter?: string;
  startDateBefore?: string;
  select?: string;
}

export function buildOfferLocationsStatus(
  locationsStatus: any[],
  type: 'link' | 'unlink',
): { type: string; message: string } {
  const { translate } = translation();
  const locationsCount = locationsStatus.filter(item => item[`${type}ed`])
    .length;

  if (locationsStatus?.length > 0) {
    if (locationsCount !== locationsStatus.length)
      return {
        type: 'error',
        message: translate(`offers.notifications.${type}.error`, {
          count: locationsStatus.length - locationsCount,
          total: locationsStatus.length,
        }),
      };

    return {
      type: 'success',
      message: translate(`offers.notifications.${type}.success`, {
        count: locationsCount,
        total: locationsStatus.length,
      }),
    };
  }

  return null;
}
