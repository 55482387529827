import http from '../services/http-service';
import config from '../utils/config';
import { Brand, BrandConsent, BrandOptions } from './brand-model';

class BrandApiService {
  createBrand(brand: Brand) {
    return http.post('brands', brand);
  }

  deleteBrand(brandId: string) {
    return http.delete(`brands/${brandId}`);
  }

  deleteBrandUser(brandId: string, created: string) {
    return http.delete(`brands/${brandId}/users/${created}`);
  }

  getBrandUsers(brandId: string) {
    return http.get(`brands/${brandId}/users`);
  }

  getBrands({ limit, last, consent }: BrandOptions = {}) {
    let params: any = { projection: 'id,name,logoURL,consent,created' };

    if (consent) params = { ...params, consent: true };

    if (limit) params = { ...params, limit };

    if (last) params = { ...params, start: JSON.stringify(last) };

    return http.get('brands', { params });
  }

  async getAllBrands(options?: BrandOptions) {
    let response = await this.getBrands(options);
    let allBrands = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getBrands({ ...options, last: response.data.last });
      allBrands = [...allBrands, ...response.data.items];
    }

    return allBrands;
  }

  async getBrandsCount() {
    let response = await this.rawGetBrandsCount();
    let { count } = response.data;

    while (response.data.last && count < config.MAX_BRANDS_COUNT) {
      const { last } = response.data;
      // eslint-disable-next-line no-await-in-loop
      response = await this.rawGetBrandsCount({ last });
      count += response.data.count;
    }

    return count;
  }

  private rawGetBrandsCount({ last }: BrandOptions = {}) {
    const params: any = { select: 'count' };
    if (last) params.start = JSON.stringify(last);
    return http.get('brands', { params });
  }

  requestConsent({
    brandId,
    programId,
    nameFirst,
    nameLast,
    email,
    title,
    skipInvite,
  }: BrandConsent) {
    const body = { nameFirst, nameLast, email, title, skipInvite };
    return http.post(`brands/${brandId}/programs/${programId}/users`, body);
  }
}

export default BrandApiService;
