import { AmountOperator, TransactionFilter } from './interfaces';
import { Transaction } from '../transactions/transaction-model';

export default function transactionMatchesFilter(
  transaction: Transaction,
  filters: TransactionFilter = {},
) {
  if (!filters) return true;

  if (filters.amountFilter)
    switch (filters.amountFilter.operator) {
      case AmountOperator.LESS:
        if (transaction.amount >= filters.amountFilter.amount) return false;
        break;
      case AmountOperator.GREATER:
        if (transaction.amount <= filters.amountFilter.amount) return false;
        break;
      case AmountOperator.EQUAL:
        if (transaction.amount !== filters.amountFilter.amount) return false;
        break;
      default:
        break;
    }

  if (filters.cardScheme && !filters.cardScheme.includes(transaction.scheme))
    return false;

  return !(filters.status && transaction.status !== filters.status);
}
