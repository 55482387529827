import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { transformToStatus } from '../status/status-model';

export interface UserState {
  error?: any;
  loading: boolean;
  details?: {
    id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
  };
}

const initialState: UserState = {
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },

    getUser(state) {
      state.loading = true;
      state.error = undefined;
    },

    getUserSuccess(state, action: PayloadAction<any>) {
      const user = action.payload;

      state.loading = false;
      state.details = {
        id: user.id,
        nameFirst: user.nameFirst,
        nameLast: user.nameLast,
        email: user.email,
      };
    },

    getUserError(state, action: any) {
      state.loading = false;
      const error = action.payload.response?.data?.error || {
        code: 'error',
        message: action.payload || 'Unexpected error',
      };
      state.error = transformToStatus(error);
    },
  },
});

export const { actions } = userSlice;
export default userSlice.reducer;
