import config from '../utils/config';
import { TransactionFilter } from '../utils/interfaces';
import {
  TransactionOptions,
  TransactionStatus,
  transformToTransaction,
} from './transaction-model';
import { actions } from './transaction-reducer';
import TransactionApiService from './transaction-api-service';

const transactionApiService: TransactionApiService = new TransactionApiService();

export const { clear } = actions;

export const clearTransaction = (transactionId: string) => async dispatch => {
  dispatch(actions.clearTransaction());

  try {
    await transactionApiService.clearTransaction(transactionId);
    dispatch(actions.clearTransactionSuccess());
  } catch (error) {
    dispatch(actions.clearTransactionError({ error }));
  }
};

export const getTransactions = (
  options: TransactionOptions,
) => async dispatch => {
  if (options.last) dispatch(actions.getMoreTransactions());
  else dispatch(actions.getTransactions());

  try {
    const response = await transactionApiService.getTransactions(options);
    const { items, last } = response.data;
    let transactions = items.map(transformToTransaction);

    if (config.PLATFORM === 'clo')
      transactions = transactions.filter(
        tx => tx.status === TransactionStatus.QUALIFIED,
      );

    if (options.last)
      dispatch(actions.getMoreTransactionsSuccess({ transactions, last }));
    else dispatch(actions.getTransactionsSuccess({ transactions, last }));
  } catch (error) {
    if (options.last) dispatch(actions.getMoreTransactionsError({ error }));
    else dispatch(actions.getTransactionsError({ error }));
  }
};

export const getTransactionsCount = (
  options: TransactionOptions,
) => async dispatch => {
  dispatch(actions.getTransactionsCount());

  try {
    const response = await transactionApiService.getTransactionsCount(options);
    dispatch(actions.getTransactionsCountSuccess(response));
  } catch (error) {
    dispatch(actions.getTransactionsCountError({ error }));
  }
};

export const getExportableTransactions = (
  options: TransactionOptions,
) => async dispatch => {
  dispatch(actions.getExportableTransactions());

  try {
    const response = await transactionApiService.getAllTransactions(options);
    let transactions = response.map(transformToTransaction);

    if (config.PLATFORM === 'clo')
      transactions = transactions.filter(
        tx => tx.status === TransactionStatus.QUALIFIED,
      );

    dispatch(actions.getExportableTransactionsSuccess(transactions));
  } catch (error) {
    dispatch(actions.getExportableTransactionsError({ error }));
  }
};

export const filterTransactions = (
  options: TransactionOptions,
  filters: TransactionFilter,
) => dispatch => {
  dispatch(actions.filterTransactions(filters));

  if (filters && filters.dateRange) {
    const { startDate, endDate }: any = filters.dateRange;
    options = { ...options, startDate, endDate };
  }

  dispatch(getTransactions(options));
};
