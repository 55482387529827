import AuthActionTypes from './auth-action-types';
import { getToken, setToken, removeToken, Role } from './auth-token';
import { transformToStatus } from '../status/status-model';

export interface AuthState {
  bootstrapped: boolean;
  error: any;
  loading: boolean;
  logged: boolean;
  passwordReset: boolean;
  passwordResetEmailSent: boolean;
  tokenState: any;
  role?: Role;
}

const initialAuthState: AuthState = {
  bootstrapped: false,
  error: null,
  loading: false,
  logged: false,
  passwordReset: false,
  passwordResetEmailSent: false,
  tokenState: {},
};

export const authReducer = (state = initialAuthState, action) => {
  /* tslint:disable-next-line:triple-equals */
  if (action == undefined) return state;

  switch (action.type) {
    case AuthActionTypes.DONE:
      switch (action.actionName) {
        case AuthActionTypes.GET_TOKEN:
          return {
            ...state,
            loading: false,
            tokenState: {
              token: action.payload.items[0].token,
              complete: action.payload.items[0].complete,
            },
          };
        case AuthActionTypes.SIGN_UP:
          return {
            ...state,
            loading: false,
          };
        case AuthActionTypes.SIGN_IN:
          setToken(action.payload.token);
          const token = getToken();

          return {
            ...state,
            tokenState: {
              token: token.encoded,
            },
            loading: false,
            logged: true,
            role: token.role,
          };
        case AuthActionTypes.SIGN_OUT:
          removeToken();
          return {
            ...initialAuthState,
            bootstrapped: true,
          };
        case AuthActionTypes.SEND_PASSWORD_RESET_EMAIL:
          return {
            ...state,
            loading: false,
            passwordResetEmailSent: true,
          };
        case AuthActionTypes.RESET_PASSWORD:
          return {
            ...state,
            loading: false,
            passwordReset: true,
          };
        default:
          return {
            ...state,
            loading: false,
          };
      }

    case AuthActionTypes.ERROR:
      let error;
      switch (action.actionName) {
        case AuthActionTypes.SIGN_IN:
        case AuthActionTypes.SIGN_OUT:
        case AuthActionTypes.SIGN_UP:
          error = toError(action);
          break;
        default:
          error =
            action.error && action.error.response
              ? action.error.response.data.error
              : action.error || { code: 'error', message: 'Unexpected error' };
      }
      return {
        ...state,
        loading: false,
        error: transformToStatus(error),
      };

    case AuthActionTypes.BOOTSTRAP:
      const notLoggedState = {
        ...state,
        bootstrapped: true,
        tokenState: null,
        logged: false,
      };

      const token = getToken();
      if (token) {
        if (!token.expired)
          return {
            ...state,
            bootstrapped: true,
            tokenState: {
              token: token.encoded,
            },
            logged: true,
            role: token.role,
          };

        removeToken();
      }
      return notLoggedState;

    case AuthActionTypes.COMPLETE_SIGN_UP:
    case AuthActionTypes.GET_TOKEN:
    case AuthActionTypes.RESET_PASSWORD:
    case AuthActionTypes.SEND_PASSWORD_RESET_EMAIL:
    case AuthActionTypes.SIGN_IN:
    case AuthActionTypes.SIGN_OUT:
    case AuthActionTypes.SIGN_UP:
      return {
        ...state,
        passwordResetEmailSent: false,
        passwordReset: false,
        loading: true,
        error: null,
      };
    default:
      return state;
  }
};

function toError(action: any) {
  const response = action && action.error && action.error.response;
  const error =
    response && ((response.data && response.data.error) || response.error);
  return error
    ? transformToStatus(error)
    : { code: 'error', message: 'Something went wrong.' };
}
