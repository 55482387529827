type ActionListener = {
  actionTypePredicate: (actionType: string) => boolean;
  actionCreator: (action: any) => any;
};

export function onAction(
  actionTypePredicate: ((actionType: string) => boolean) | string | string[],
  actionCreator: (action: any) => any,
): ActionListener {
  let predicateAsFunction: (actionType: string) => boolean;

  if (typeof actionTypePredicate === 'string') {
    predicateAsFunction = (actionType: string) =>
      actionType === actionTypePredicate;
  } else if (Array.isArray(actionTypePredicate)) {
    predicateAsFunction = (actionType: string) =>
      actionTypePredicate.includes(actionType);
  } else {
    predicateAsFunction = actionTypePredicate;
  }

  return {
    actionTypePredicate: predicateAsFunction,
    actionCreator,
  };
}

export function createActionListenerMiddleware(listeners: ActionListener[]) {
  return store => next => action => {
    next(action);

    if (!action.type) return;

    const matchingListeners = listeners.filter(({ actionTypePredicate }) =>
      actionTypePredicate(action.type),
    );

    matchingListeners.forEach(({ actionCreator }) => {
      store.dispatch(actionCreator(action));
    });
  };
}
