import { Moment } from 'moment';

export enum AmountOperator {
  EQUAL = '==',
  GREATER = '>',
  LESS = '<',
}

export interface DateRange {
  startDate?: Moment | string | null;
  endDate?: Moment | string | null;
}

export interface TransactionFilter {
  status?: string;
  amountFilter?: { operator: AmountOperator; amount: number };
  cardScheme?: string;
  dateRange?: DateRange;
}

export interface FidelRoute {
  path: string;
  label: any;
  component: any;
  props?: any;
  hidden?: boolean;
}

export interface Form {
  handleForm: (e: any) => void;
  handleFormError: () => void;
  isFormValid: () => boolean;
  checkForm?: (error: boolean) => void;
}

export interface RawDetailArgs {
  headers?: any;
  id: string;
  params?: {};
}
