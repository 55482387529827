import { NewAccount } from '../account/account-model';
import clearAll from '../clear-all';
import AuthApiService from './auth-api-service';
import Types from './auth-action-types';

import liveService from '../services/live-service';
import { setLive } from '../live/live-actions';

const authService: AuthApiService = new AuthApiService();

export const actions = {
  error: (err: any, actionName: string) => ({
    type: Types.ERROR,
    error: err,
    actionName,
  }),
  done: (data: any, actionName: string) => ({
    type: Types.DONE,
    payload: data,
    actionName,
  }),
  bootstrap: () => ({ type: Types.BOOTSTRAP }),
  completeSignUp: () => ({ type: Types.COMPLETE_SIGN_UP }),
  getToken: () => ({ type: Types.GET_TOKEN }),
  resetPassword: () => ({ type: Types.RESET_PASSWORD }),
  sendPasswordResetEmail: () => ({ type: Types.SEND_PASSWORD_RESET_EMAIL }),
  signIn: () => ({ type: Types.SIGN_IN }),
  signOut: () => ({ type: Types.SIGN_OUT }),
  signUp: () => ({ type: Types.SIGN_UP }),
};

export const bootstrap = () => dispatch => {
  dispatch(actions.bootstrap());
  dispatch(actions.done({}, Types.BOOTSTRAP));
};

export const getToken = (tokenId: string) => async dispatch => {
  dispatch(actions.getToken());

  try {
    const response = await authService.getToken(tokenId);
    dispatch(actions.done(response.data, Types.GET_TOKEN));
  } catch (error) {
    dispatch(actions.error(error, Types.GET_TOKEN));
  }
};

export const resetPassword = (
  token: string,
  password: string,
) => async dispatch => {
  dispatch(actions.resetPassword());

  try {
    const response = await authService.resetPassword(token, password);
    dispatch(actions.done(response.data, Types.RESET_PASSWORD));
  } catch (error) {
    dispatch(actions.error(error, Types.RESET_PASSWORD));
  }
};

export const sendPasswordResetEmail = (email: string) => async dispatch => {
  dispatch(actions.sendPasswordResetEmail());

  try {
    const response = await authService.sendPasswordResetEmail(email);
    dispatch(actions.done(response.data, Types.SEND_PASSWORD_RESET_EMAIL));
  } catch (error) {
    dispatch(actions.error(error, Types.SEND_PASSWORD_RESET_EMAIL));
  }
};

export const signIn = (email: string, password: string) => async dispatch => {
  dispatch(actions.signIn());

  try {
    const response = await authService.signIn(email, password);
    dispatch(actions.done(response.data, Types.SIGN_IN));
    dispatch(setLive(liveService.isLive()));
  } catch (error) {
    dispatch(actions.error(error, Types.SIGN_IN));
  }
};

export const signOut = (token: string) => async dispatch => {
  dispatch(actions.signOut());

  try {
    const response = await authService.signOut(token);
    dispatch(actions.done(response.data, Types.SIGN_OUT));
    dispatch(clearAll());
  } catch (error) {
    dispatch(actions.error(error, Types.SIGN_OUT));
  }
};

export const signUp = (
  payload: NewAccount,
  postLogIn = true,
) => async dispatch => {
  dispatch(actions.signUp());

  try {
    const response = await authService.signUp(payload);
    dispatch(actions.done(response.data, Types.SIGN_UP));
    if (postLogIn) {
      dispatch(signIn(payload.email, payload.password));
    }
  } catch (error) {
    dispatch(actions.error(error, Types.SIGN_UP));
  }
};

export const completeSignUp = (
  token: string,
  tokenId: string,
  email: string,
  password: string,
) => async dispatch => {
  dispatch(actions.completeSignUp());

  try {
    await authService.completeSignUp(token, tokenId, password);
    dispatch(signIn(email, password));
  } catch (error) {
    dispatch(actions.error(error, Types.COMPLETE_SIGN_UP));
  }
};
