import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Program } from '../programs/program-reducer';

export interface LocationsFilters {
  status?: string;
  postcode?: string;
  brandId?: string;
}

export interface LocationsState {
  counts: Record<string, { loading: boolean; count?: number }>;
  filters: LocationsFilters;
  locations: {
    [programId: string]: {
      loading: boolean;
      error?: any;
      items?: any[];
      last?: any;
    };
  };
  creating: boolean;
  updating: boolean;
  deleting: boolean;
}

const initialState: LocationsState = {
  counts: {},
  locations: {},
  filters: {},
  creating: false,
  updating: false,
  deleting: false,
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clear: () => initialState,
    createLocation(state) {
      state.creating = true;
    },
    createLocationSuccess(state) {
      state.creating = false;
    },
    createLocationError(state) {
      state.creating = false;
    },

    updateLocation(state) {
      state.updating = true;
    },
    updateLocationSuccess(state) {
      state.updating = false;
    },
    updateLocationError(state) {
      state.updating = false;
    },

    deleteLocation(state) {
      state.deleting = true;
    },
    deleteLocationSuccess(state) {
      state.deleting = false;
    },
    deleteLocationError(state) {
      state.deleting = false;
    },

    getAllLocations(state, action: PayloadAction<Program[]>) {
      action.payload.forEach(({ id }) => {
        state.locations[id] = { loading: true };
      });
    },

    getAllLocationsDone(state, action: PayloadAction<any[]>) {
      action.payload.forEach(({ programId, items, error }) => {
        if (error) state.locations[programId] = { loading: false, error };
        else state.locations[programId] = { loading: false, items };
      });
    },

    getLocations(state, action: PayloadAction<string>) {
      state.locations = {
        [action.payload]: { loading: true },
      };
    },
    getLocationsSuccess(state, action: PayloadAction<any>) {
      const { programId, last, items } = action.payload;

      state.locations[programId] = { items, last, loading: false };
    },
    getLocationsError(state, action: PayloadAction<any>) {
      const { programId, error } = action.payload;
      state.locations[programId].loading = false;
      state.locations[programId].error = error;
    },

    getMoreLocations(state, action: PayloadAction<string>) {
      state.locations[action.payload].loading = true;
    },
    getMoreLocationsSuccess(state, action: PayloadAction<any>) {
      const { programId, last, items } = action.payload;

      state.locations[programId].loading = false;
      state.locations[programId].last = last;
      state.locations[programId].items.push(...items);
    },
    getMoreLocationsError(state, action: PayloadAction<any>) {
      const { programId, error } = action.payload;
      state.locations[programId].loading = false;
      state.locations[programId].error = error;
    },

    getLocationsCount(state, action: PayloadAction<{ programId: string }>) {
      const { programId } = action.payload;
      state.counts[programId] = {
        loading: true,
      };
    },
    getLocationsCountSuccess(
      state,
      action: PayloadAction<{ count: number; programId: string }>,
    ) {
      const { programId, count } = action.payload;

      state.counts[programId] = {
        count,
        loading: false,
      };
    },
    getLocationsCountError(
      state,
      action: PayloadAction<{ programId: string }>,
    ) {
      const { programId } = action.payload;
      state.counts[programId] = {
        loading: false,
      };
    },

    setFilters(state, action: PayloadAction<LocationsFilters>) {
      state.filters = action.payload;
    },
  },
});

export default locationsSlice.reducer;
export const { actions } = locationsSlice;
