import BrandApiService from './brand-api-service';
import { actions } from './brand-reducer';
import { BrandConsent, CreateBrandOptions, BrandOptions } from './brand-model';

const brandApiService: BrandApiService = new BrandApiService();

export const { clear } = actions;

export const createBrand = ({
  brand,
  consent,
  live,
}: CreateBrandOptions) => async dispatch => {
  dispatch(actions.createBrand());

  try {
    const response = await brandApiService.createBrand(brand);

    if (live)
      dispatch(
        requestConsent({
          ...consent,
          brandId: (response.data?.items?.[0] || {}).id,
        }),
      );
    else {
      dispatch(getBrands());
      dispatch(getBrandsCount());
    }
  } catch (error) {
    dispatch(actions.createBrandError({ error }));
  }
};

export const deleteBrand = (brandId: string) => async dispatch => {
  dispatch(actions.deleteBrand());

  try {
    await brandApiService.deleteBrand(brandId);
    dispatch(getBrands());
    dispatch(getBrandsCount());
  } catch (error) {
    dispatch(actions.deleteBrandError({ error }));
  }
};

export const deleteBrandUser = (
  brandId: string,
  created: string,
) => async dispatch => {
  dispatch(actions.deleteBrandUser());

  try {
    await brandApiService.deleteBrandUser(brandId, created);
    dispatch(getBrandUsers(brandId));
  } catch (error) {
    dispatch(actions.deleteBrandUserError({ error }));
  }
};

export const getAllBrands = (options?: BrandOptions) => async dispatch => {
  dispatch(actions.getAllBrands());

  try {
    const response = await brandApiService.getAllBrands(options);
    dispatch(actions.getAllBrandsSuccess(response));
  } catch (error) {
    dispatch(actions.getAllBrandsError({ error }));
  }
};

export const getBrands = (options?: BrandOptions) => async dispatch => {
  if (options?.last) dispatch(actions.getMoreBrands());
  else dispatch(actions.getBrands());

  try {
    const { data } = await brandApiService.getBrands(options);
    if (options?.last) dispatch(actions.getMoreBrandsSuccess(data));
    else dispatch(actions.getBrandsSuccess(data));
  } catch (error) {
    if (options?.last) dispatch(actions.getMoreBrandsError({ error }));
    else dispatch(actions.getBrandsError({ error }));
  }
};

export const getBrandUsers = (brandId: string) => async dispatch => {
  dispatch(actions.getBrandUsers());

  try {
    const response = await brandApiService.getBrandUsers(brandId);
    dispatch(
      actions.getBrandUsersSuccess({ brandId, items: response.data.items }),
    );
  } catch (error) {
    dispatch(actions.getBrandUsersError({ error }));
  }
};

export const getBrandsCount = () => async dispatch => {
  dispatch(actions.getBrandsCount());

  try {
    const response = await brandApiService.getBrandsCount();
    dispatch(actions.getBrandsCountSuccess(response));
  } catch (error) {
    dispatch(actions.getBrandsCountError({ error }));
  }
};

export const requestConsent = (consent: BrandConsent) => async dispatch => {
  dispatch(actions.requestConsent());

  try {
    await brandApiService.requestConsent(consent);
    dispatch(getBrands());
    dispatch(getBrandsCount());
    dispatch(getBrandUsers(consent.brandId));
  } catch (error) {
    dispatch(actions.requestConsentError({ error }));
  }
};
