/* eslint-disable no-await-in-loop */

import config from '../utils/config';
import http from '../services/http-service';
import { TransactionOptions } from './transaction-model';

class TransactionApiService {
  public clearTransaction(transactionId: string) {
    return http.patch(`transactions/${transactionId}`, { cleared: true });
  }

  public getTransactions({
    brandId,
    offerId,
    programId,
    startDate,
    endDate,
    last,
  }: TransactionOptions) {
    const headers: any = {};
    let params: any = {};
    const user = localStorage.getItem(config.FIDEL_USER);

    if (offerId && user) headers['fidel-user'] = user;

    if (!!startDate && !!endDate)
      params = { ...params, from: `${startDate}`, to: `${endDate}` };

    if (last) params = { ...params, start: JSON.stringify(last) };

    let url: string;
    if (offerId) url = `offers/${offerId}/transactions`;
    else if (config.PLATFORM === 'dashboard' && !brandId)
      url = `programs/${programId}/transactions`;
    else url = `brands/${brandId}/programs/${programId}/transactions`;

    return http.get(url, { headers, params });
  }

  public async getAllTransactions(options: TransactionOptions) {
    let response = await this.getTransactions(options);
    let allTransactions = response.data.items;

    while (response.data.last) {
      response = await this.getTransactions({
        ...options,
        last: response.data.last,
      });

      allTransactions = [...allTransactions, ...response.data.items];
    }

    return allTransactions;
  }

  public async getTransactionsCount(options: TransactionOptions) {
    let response = await this.rawGetTransactionsCount(options);
    let { count } = response.data;

    while (response.data.last && count < config.MAX_TRANSACTION_COUNT) {
      const { last } = response.data;
      response = await this.rawGetTransactionsCount({ ...options, last });
      count += response.data.count;
    }

    return count;
  }

  private rawGetTransactionsCount(options: TransactionOptions) {
    const { brandId, programId, last, startDate, endDate } = options;

    const url =
      config.PLATFORM === 'dashboard' && !brandId
        ? `programs/${programId}/transactions`
        : `brands/${brandId}/programs/${programId}/transactions`;

    const params: any = { select: 'count' };

    if (last) params.start = JSON.stringify(last);

    if (startDate && endDate) {
      params.from = startDate;
      params.to = endDate;
    }

    return http.get(url, { params });
  }
}

export default TransactionApiService;
