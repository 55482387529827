import config from '../../utils/config';
import OfferApiService from '../offer-api-service';
import * as UserActions from '../../user/user-actions';
import { getOffers, getOffersCount } from '../offer-actions';
import {
  buildOfferLocationsStatus,
  Offer,
  PaymentInfo,
  RejectInfo,
} from '../offer-model';
import { actions as statusActions } from '../../status/status-reducer';
import { actions } from './current-offer-reducer';

const offerService: OfferApiService = new OfferApiService();

const setOfferLocationsStatus = ({ type, message }) => async dispatch => {
  if (type === 'error') {
    dispatch(statusActions.status({ level: 'error', message }));
  } else {
    dispatch(statusActions.status({ level: 'success', message }));
  }
};

export const { clear, clearError } = actions;

export const getOffer = (offerId: string) => async dispatch => {
  dispatch(actions.getOffer());

  try {
    const response = await offerService.getOffer(offerId);
    dispatch(actions.getOfferSuccess(response.data));
  } catch (error) {
    dispatch(actions.getOfferError({ error }));
  }
};

export const rejectOffer = (
  offerId: string,
  rejectInfo: RejectInfo,
) => async dispatch => {
  dispatch(actions.rejectOffer());

  try {
    await offerService.rejectOffer(offerId, rejectInfo);
    dispatch(actions.rejectOfferSuccess());
    dispatch(getOffers());
    dispatch(getOffersCount());
  } catch (error) {
    dispatch(actions.rejectOfferError({ error }));
  }
};

export const linkCardToOffer = (
  cardId: string,
  offerId: string,
) => async dispatch => {
  dispatch(actions.linkCardToOffer());

  try {
    await offerService.linkCardToOffer(cardId, offerId);
    dispatch(actions.linkCardToOfferSuccess());
  } catch (error) {
    dispatch(actions.linkCardToOfferError({ error }));
  }
};

export const linkLocation = (
  locationId: string,
  offerId: string,
) => async dispatch => {
  dispatch(actions.linkLocation());

  try {
    await offerService.linkLocation(locationId, offerId);
    dispatch(actions.linkLocationSuccess());
  } catch (error) {
    dispatch(actions.linkLocationError({ error }));
  }
};

export const unlinkLocation = (
  locationId: string,
  offerId: string,
) => async dispatch => {
  dispatch(actions.unlinkLocation());

  try {
    await offerService.unlinkLocation(locationId, offerId);
    dispatch(actions.unlinkLocationSuccess());
  } catch (error) {
    dispatch(actions.unlinkLocationError({ error }));
  }
};

export const linkLocations = (
  offerId: string,
  locations: string[],
) => async dispatch => {
  dispatch(actions.linkLocations());

  const payload = await Promise.all(
    locations.map(locationId =>
      offerService
        .linkLocation(locationId, offerId)
        .then(() => ({ locationId, linked: true }))
        .catch(() => ({ locationId, linked: false })),
    ),
  );

  dispatch(actions.linkLocationsSuccess(payload));

  const status = buildOfferLocationsStatus(payload, 'link');
  if (status) dispatch(setOfferLocationsStatus(status));
};

export const unlinkLocations = (
  offerId: string,
  locations: string[],
) => async dispatch => {
  dispatch(actions.unlinkLocations());

  const payload = await Promise.all(
    locations.map(locationId =>
      offerService
        .unlinkLocation(locationId, offerId)
        .then(() => ({ locationId, unlinked: true }))
        .catch(() => ({ locationId, unlinked: false })),
    ),
  );

  dispatch(actions.unlinkLocationsSuccess(payload));

  const status = buildOfferLocationsStatus(payload, 'unlink');
  if (status) dispatch(setOfferLocationsStatus(status));
};

export const getAllLocations = (offerId: string) => async dispatch => {
  dispatch(actions.getAllLocations(offerId));

  try {
    const response = await offerService.getAllLocations(offerId);
    dispatch(actions.getAllLocationsSuccess({ offerId, items: response }));
  } catch (error) {
    dispatch(actions.getAllLocationsError({ offerId, error }));
  }
};

export const createOffer = (
  offer: Offer,
  locations?: string[],
) => async dispatch => {
  dispatch(actions.createOffer());

  try {
    const { data } = await offerService.createOffer(offer);
    dispatch(actions.createOfferSuccess(data));
    if (locations) dispatch(linkLocations(data.items[0].id, locations));
    dispatch(getOffers());
    dispatch(getOffersCount());
  } catch (error) {
    dispatch(actions.createOfferError({ error }));
  }
};

export const processPayment = (
  offer: Offer,
  payment: PaymentInfo,
) => async dispatch => {
  dispatch(actions.processPayment());

  try {
    await offerService.processPayment(offer.brandId, payment);
    dispatch(actions.processPaymentSuccess());
    dispatch(
      UserActions.getUser(localStorage.getItem(config.FIDEL_AUTH_TOKEN) || ''),
    );
  } catch (error) {
    dispatch(actions.processPaymentError({ error }));
  }
};

export const updateOffer = (
  offerId: string,
  offer: Offer,
  locations?: { link?: string[]; unlink?: string[] },
) => async dispatch => {
  dispatch(actions.updateOffer());

  try {
    const { data } = await offerService.updateOffer(offerId, offer);
    dispatch(actions.updateOfferSuccess(data));
    if (locations?.link)
      dispatch(linkLocations(data.items[0].id, locations.link));
    if (locations?.unlink)
      dispatch(unlinkLocations(data.items[0].id, locations.unlink));
    dispatch(getOffers());
    dispatch(getOffersCount());
  } catch (error) {
    dispatch(actions.updateOfferError({ error }));
  }
};

export const deleteOffer = (offerId: string) => async dispatch => {
  dispatch(actions.deleteOffer());

  try {
    await offerService.deleteOffer(offerId);
    dispatch(actions.deleteOfferSuccess());
    dispatch(getOffers());
    dispatch(getOffersCount());
  } catch (error) {
    dispatch(actions.deleteOfferError({ error }));
  }
};
