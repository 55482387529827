import moment from 'moment';

export interface TransactionOptions {
  programId?: string;
  brandId?: string;
  offerId?: string;
  startDate?: string;
  endDate?: string;
  last?: any;
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  QUALIFIED = 'QUALIFIED',
  REFUND = 'REFUND',
  REJECTED = 'REJECTED',
}

interface TransactionOffer {
  cashback: number;
  message: string[];
  id: string;
  performanceFee: number;
  qualificationDate: string;
  qualified: boolean;
}

export function transformToTransactionOffer(data: any): TransactionOffer {
  return {
    cashback: data.cashback,
    message: data.message || [],
    id: data.id,
    performanceFee: data.performanceFee,
    qualificationDate: data.qualificationDate,
    qualified: data.qualified,
  };
}

interface TransactionBrand {
  id?: string;
  logoURL?: string;
  metadata?: string;
  name?: string;
}

export interface Transaction {
  id: string;
  accountId: string;
  address: string;
  amount: number;
  auth: boolean;
  brand: TransactionBrand;
  city: string;
  countryCode: string;
  cleared: boolean;
  created: string;
  currency: string;
  date: string;
  lastNumbers: string;
  merchantId: string;
  offer: TransactionOffer | {};
  postcode: string;
  qualificationDate: string;
  scheme: string;
  status: string;
}

export function transformToTransaction(data: any): Transaction {
  const transaction: Transaction = {
    id: data.id,
    accountId: data.accountId,
    amount: data.amount,
    auth: data.auth,
    brand: data.brand,
    cleared: data.cleared,
    created: data.created,
    currency: data.currency,
    date: data.date || data.datetime,
    lastNumbers: data.lastNumbers || (data.card && data.card.lastNumbers),
    offer: data.offer ? transformToTransactionOffer(data.offer) : {},
    address: data.address || (data.location && data.location.address),
    city: data.city || (data.location && data.location.city),
    postcode: data.postcode || (data.location && data.location.postcode),
    countryCode:
      data.countryCode || (data.location && data.location.countryCode),
    merchantId: data.merchantId || (data.location && data.location.id),
    qualificationDate:
      data.qualificationDate || (data.offer && data.offer.qualificationDate),
    scheme: data.scheme || (data.card && data.card.scheme),
    status: undefined,
  };

  if (transaction.amount < 0) transaction.status = TransactionStatus.REFUND;
  else if (data.offer) {
    const transactionOffer = transaction.offer as TransactionOffer;

    if (transactionOffer.qualified && transaction.cleared) {
      transaction.status = TransactionStatus.QUALIFIED;
    } else if (
      transactionOffer.qualificationDate &&
      moment(transactionOffer.qualificationDate).isBefore(moment())
    ) {
      transaction.status = TransactionStatus.PENDING;
    } else if (transactionOffer.message.length > 0) {
      transaction.status = TransactionStatus.REJECTED;
    } else {
      transaction.status = TransactionStatus.PENDING;
    }
  } else if (data.offer == undefined)
    transaction.status = TransactionStatus.PENDING;

  return transaction;
}
