import jwtDecode from 'jwt-decode';
import config from '../utils/config';

export type Role = 'account' | 'moderator' | 'super';

export interface Token {
  encoded: string;
  expired: boolean;
  userId: string;
  role: Role;
  accountId?: string;
}

export function transformToToken(encodedToken: string): Token {
  const decodedToken = jwtDecode(encodedToken);

  return {
    encoded: encodedToken,
    expired: isExpired(decodedToken.exp),
    userId: decodedToken?.permission?.userId,
    role: decodedToken?.permission?.role,
    accountId: decodedToken?.permission?.accountId,
  };
}

export function removeToken() {
  localStorage.removeItem(config.FIDEL_AUTH_TOKEN);
}

export function setToken(encodedToken: string) {
  localStorage.setItem(config.FIDEL_AUTH_TOKEN, encodedToken);
}

export function getEncodedToken() {
  return localStorage.getItem(config.FIDEL_AUTH_TOKEN);
}

export function getToken(): Token | undefined {
  const encodedToken = getEncodedToken();
  if (encodedToken) {
    return transformToToken(encodedToken);
  }

  return undefined;
}

function isExpired(expiryDate: any) {
  return new Date(Date.now()) >= new Date(expiryDate * 1000);
}
