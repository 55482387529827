import { Program } from '../programs/program-reducer';
import { State } from '../create-store';

import LocationsApiService, {
  CreateLocationPayload,
  UpdateLocationPayload,
} from './locations-api-service';

import { actions } from './locations-reducer';

const locationsService = new LocationsApiService();

export const { clear, setFilters } = actions;

export const getLocations = (programId: string, last?: any) => async (
  dispatch,
  getState,
) => {
  const isLoadMore = !!last;

  dispatch(
    isLoadMore
      ? actions.getMoreLocations(programId)
      : actions.getLocations(programId),
  );

  try {
    const { filters } = (getState() as State).locations;
    const response = await locationsService.getLocations({
      programId,
      filters,
      last,
    });
    const { items, last: newLast } = response.data;
    const payload = { programId, items, last: newLast };

    if (isLoadMore) {
      dispatch(actions.getMoreLocationsSuccess(payload));
    } else {
      dispatch(actions.getLocationsSuccess(payload));
    }
  } catch (error) {
    if (isLoadMore) {
      dispatch(actions.getMoreLocationsError({ programId, error }));
    } else {
      dispatch(actions.getLocationsError({ programId, error }));
    }
  }
};

export const getAllLocations = (programs: Program[]) => async dispatch => {
  dispatch(actions.getAllLocations(programs));

  const payload = await Promise.all(
    programs.map(({ id: programId }) =>
      locationsService
        .getAllLocations({
          programId,
        })
        .then(items => ({ programId, items }))
        .catch(error => ({ programId, error })),
    ),
  );

  dispatch(actions.getAllLocationsDone(payload));
};

export const getMoreLocations = (programId: string) => async (
  dispatch,
  getState,
) => {
  const location = (getState() as State).locations.locations[programId];

  if (location) dispatch(getLocations(programId, location.last));
};

export const getLocationsCount = (programId: string) => async dispatch => {
  dispatch(actions.getLocationsCount({ programId }));

  try {
    const count = await locationsService.getLocationsCount(programId);
    dispatch(actions.getLocationsCountSuccess({ programId, count }));
  } catch (error) {
    dispatch(actions.getLocationsCountError({ programId }));
  }
};

export const createLocation = (
  programId: string,
  location: CreateLocationPayload,
) => async dispatch => {
  dispatch(actions.createLocation());

  try {
    await locationsService.createLocation(programId, location);
    dispatch(actions.createLocationSuccess());
    dispatch(getLocations(programId));
    dispatch(getLocationsCount(programId));
  } catch (error) {
    dispatch(actions.createLocationError());
  }
};

export const deleteLocation = (
  programId: string,
  locationIds: string | string[],
) => async dispatch => {
  dispatch(actions.deleteLocation());

  const arrayLocationIds = Array.isArray(locationIds)
    ? locationIds
    : [locationIds];

  try {
    await Promise.all(
      arrayLocationIds.map(locationId =>
        locationsService.deleteLocation(locationId),
      ),
    );

    dispatch(actions.deleteLocationSuccess());
    dispatch(getLocations(programId));
    dispatch(getLocationsCount(programId));
  } catch (error) {
    dispatch(actions.deleteLocationError());
  }
};

export const updateLocation = (
  programId: string,
  locationId: string,
  location: UpdateLocationPayload,
) => async dispatch => {
  dispatch(actions.updateLocation());

  try {
    await locationsService.updateLocation(locationId, location);
    dispatch(actions.updateLocationSuccess());
    dispatch(getLocations(programId));
  } catch (error) {
    dispatch(actions.updateLocationError());
  }
};
