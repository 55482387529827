import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Card, PayloadCardError } from './cards-types';

export interface CardsState {
  cards: Card[];
  counting: boolean;
  creating: boolean;
  count: number;
  error: any;
  exportableCards: Card[];
  exporting: boolean;
  last: any;
  loading: boolean;
}

const initialState: CardsState = {
  cards: [],
  counting: false,
  creating: false,
  count: 0,
  exportableCards: [],
  exporting: false,
  error: null,
  last: null,
  loading: false,
};

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    clear: () => initialState,
    getCards(state) {
      state.loading = true;
    },
    getCardsSuccess(
      state,
      action: PayloadAction<{ items: Card[]; last: any }>,
    ) {
      const { items, last } = action.payload;
      state.loading = false;
      state.cards = items;
      state.last = last;
    },
    getCardsError(state, action: PayloadAction<PayloadCardError>) {
      const { error } = action.payload;
      state.error = error;
      state.loading = false;
    },
    getExportableCards(state) {
      state.exporting = true;
      state.exportableCards = [];
    },
    getExportableCardsSuccess(state, action: PayloadAction<{ cards: Card[] }>) {
      const { cards } = action.payload;
      state.exporting = false;
      state.exportableCards = cards;
    },
    getExportableCardsError(state, action: PayloadAction<PayloadCardError>) {
      const { error } = action.payload;
      state.error = error;
      state.exporting = false;
    },
    getMoreCards(state) {
      state.loading = true;
    },
    getMoreCardsSuccess(
      state,
      action: PayloadAction<{ items: Card[]; last: any }>,
    ) {
      const { items, last } = action.payload;
      state.loading = false;
      state.cards = [...state.cards, ...items];
      state.last = last;
    },
    getMoreCardsError(state, action: PayloadAction<PayloadCardError>) {
      const { error } = action.payload;
      state.error = error;
      state.loading = false;
    },
    getCardsCount(state) {
      state.counting = true;
    },
    getCardsCountSuccess(state, action: PayloadAction<number>) {
      state.counting = false;
      state.count = action.payload;
    },
    getCardsCountError(state, action: PayloadAction<PayloadCardError>) {
      const { error } = action.payload;
      state.error = error;
      state.counting = false;
    },
  },
});

export default cardsSlice.reducer;
export const { actions } = cardsSlice;
