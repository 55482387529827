import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Program {
  id: string;
  name: string;
  sync: boolean;
  syncStats: any;
}

export interface ProgramState {
  loading: boolean;
  programs: Record<string, Program>;
  creating: boolean;
  error?: any;
  lastProgram?: any;
}

const initialState: ProgramState = {
  programs: {},
  loading: false,
  creating: false,
};

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    clear: () => initialState,
    createProgram(state) {
      state.creating = true;
      state.error = undefined;
    },
    createProgramSuccess(state) {
      state.creating = false;
    },
    createProgramError(state, action: PayloadAction<any>) {
      state.creating = false;
      state.error = action.payload.error;
    },
    getPrograms(state) {
      state.loading = true;
      state.error = undefined;
      state.lastProgram = undefined;
    },
    getProgramsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.programs = action.payload.items.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {});
      state.lastProgram = action.payload.last;
    },
    getProgramsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getProgram(state) {
      state.loading = true;
      state.error = undefined;
    },
    getProgramSuccess(state, action: PayloadAction<any>) {
      const program = action.payload.items[0];
      state.loading = false;
      state.programs = {
        ...state.programs,
        [program.id]: program,
      };
    },
    getProgramError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    updateProgram(state) {
      state.creating = true;
      state.error = undefined;
    },
    updateProgramSuccess(state) {
      state.creating = false;
    },
    updateProgramError(state, action: PayloadAction<any>) {
      state.creating = false;
      state.error = action.payload.error;
    },
    syncProgram(state) {
      state.loading = true;
      state.error = undefined;
    },
    syncProgramSuccess(state, action: PayloadAction<any>) {
      const program = action.payload.items[0];
      state.loading = false;
      state.programs = {
        ...state.programs,
        [program.id]: program,
      };
    },
    syncProgramError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const { actions } = programsSlice;
export default programsSlice.reducer;
