import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Brand {
  id: string;
  name: string;
  logoURL: string;
  consent?: boolean;
}

export interface BrandState {
  loading: boolean;
  brands: Brand[];
  brandUsers: any[];
  brandsCount: number;
  lastBrand: any;
  error?: any;
}

const initialState: BrandState = {
  brands: [],
  brandUsers: [],
  brandsCount: 0,
  lastBrand: null,
  loading: false,
};

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    clear: () => initialState,
    createBrand(state) {
      state.loading = true;
      state.error = undefined;
    },
    createBrandError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    deleteBrand(state) {
      state.loading = true;
      state.error = undefined;
    },
    deleteBrandError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    deleteBrandUser(state) {
      state.loading = true;
      state.error = undefined;
    },
    deleteBrandUserError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getAllBrands(state) {
      state.loading = true;
      state.error = undefined;
    },
    getAllBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = action.payload.map(brand => brand as Brand);
    },
    getAllBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getBrands(state) {
      state.loading = true;
      state.error = undefined;
      state.lastBrand = undefined;
    },
    getBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = action.payload.items.map(brand => brand as Brand);
      state.lastBrand = action.payload.last;
    },
    getBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getMoreBrands(state) {
      state.loading = true;
      state.error = undefined;
    },
    getMoreBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brands = [
        ...state.brands,
        ...action.payload.items.map(brand => brand as Brand),
      ];
      state.lastBrand = action.payload.last;
    },
    getMoreBrandsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getBrandsCount(state) {
      state.loading = true;
      state.error = undefined;
    },
    getBrandsCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brandsCount = action.payload;
    },
    getBrandsCountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getBrandUsers(state) {
      state.loading = true;
      state.error = undefined;
    },
    getBrandUsersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.brandUsers =
        action.payload?.items.map(brand => brand as Brand) || [];
    },
    getBrandUsersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    requestConsent(state) {
      state.loading = true;
      state.error = undefined;
    },
    requestConsentError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export default brandSlice.reducer;
export const { actions } = brandSlice;
