import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Offer, transformToOffer } from './offer-model';

type OffersType = { items: Offer[]; count?: number; last?: any };

export interface Offers {
  expired: OffersType;
  allSet: OffersType;
  live: OffersType;
  pending: OffersType;
  all?: Offer[];
}

export interface OfferState {
  bootstrapped: boolean;
  error: any;
  loading: boolean;
  offers: Offers;
}

const initialState: OfferState = {
  bootstrapped: false,
  error: null,
  loading: false,
  offers: {
    allSet: { items: [] },
    expired: { items: [] },
    live: { items: [] },
    pending: { items: [] },
  },
};

const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    clear: () => initialState,
    getAllOffers(state) {
      state.loading = true;
    },
    getAllOffersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      state.offers.all = action.payload.map(transformToOffer);
    },
    getAllOffersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getOffers(state) {
      state.loading = true;
    },
    getOffersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      ['pending', 'allSet', 'live', 'expired'].forEach(type => {
        state.offers[type] = {
          count: state.offers[type]?.count || 0,
          last: action.payload[type].last,
          items: action.payload[type].items.map(transformToOffer),
        };
      });
    },
    getOffersError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getOffersByType(state, action: PayloadAction<any>) {
      state.loading = true;
      state.offers[action.payload] = {
        count: state.offers[action.payload].count,
        items: [],
        last: null,
      };
    },
    getOffersByTypeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      state.offers[action.payload.type] = {
        count: state.offers[action.payload.type].count,
        last: action.payload.last,
        items: action.payload.items.map(transformToOffer),
      };
    },
    getOffersByTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getMoreOffersByType(state) {
      state.loading = true;
    },
    getMoreOffersByTypeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      state.offers[action.payload.type] = {
        count: state.offers[action.payload.type].count,
        last: action.payload.last,
        items: [
          ...state.offers[action.payload.type].items,
          ...action.payload.items.map(transformToOffer),
        ],
      };
    },
    getMoreOffersByTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getOffersCount(state) {
      state.loading = true;
    },
    getOffersCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.bootstrapped = true;
      ['pending', 'allSet', 'live', 'expired'].forEach(type => {
        state.offers[type].count = action.payload[`${type}Count`];
      });
    },
    getOffersCountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export default offerSlice.reducer;
export const { actions } = offerSlice;
