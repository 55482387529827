import BrandUserApiService from './brand-user-api-service';

import { actions as userActions } from '../user/user-reducer';
import { actions } from './brand-user-reducer';

const brandUserApiService = new BrandUserApiService();

export const { clear } = actions;

export const updateBusiness = (userId: string, data: any) => async dispatch => {
  dispatch(actions.updateBusiness());

  try {
    const response = await brandUserApiService.updateBusiness(userId, data);
    const { businessInformation } = response.data.items[0];
    dispatch(actions.updateBusinessSuccess(businessInformation));
  } catch (error) {
    dispatch(actions.updateBusinessError(error));
  }
};

export const getInvoices = (brandId: string) => async dispatch => {
  dispatch(actions.getInvoices());

  try {
    const response = await brandUserApiService.getInvoices(brandId);

    dispatch(actions.getInvoicesSuccess(response.data.items));
  } catch (error) {
    dispatch(actions.getInvoicesError(error));
  }
};

export const completeStep = (
  userId: string,
  steps: any,
  completedStep: string,
) => async dispatch => {
  dispatch(actions.completeStep());

  try {
    const response = await brandUserApiService.completeStep(
      userId,
      steps,
      completedStep,
    );

    const user = response.data.items[0];

    dispatch(userActions.getUserSuccess(user));
    dispatch(actions.completeStepSuccess(user));
  } catch (error) {
    dispatch(userActions.getUserError(error));
    dispatch(actions.completeStepError(error));
  }
};
