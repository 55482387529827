import http from '../services/http-service';

class ProgramApiService {
  public async getProgram(programId: string) {
    return http.get(`programs/${programId}`);
  }

  public async getPrograms(options?: any) {
    return http.get('programs', {
      params: {
        projection: 'id,name,active,sync,created',
        ...options,
      },
    });
  }

  public async createProgram(name: string) {
    return http.post('programs', {
      name,
    });
  }

  public async updateProgram(programId: string, name: string) {
    return http.patch(`programs/${programId}`, {
      name,
    });
  }

  public async syncProgram(programId: string) {
    return http.post(`programs/${programId}/sync`, {});
  }
}

export default ProgramApiService;
