import { onAction } from '../utils/action-listen-middleware';
import AuthActionTypes from '../auth/auth-action-types';

import { actions } from './status-reducer';

export const statusDone = onAction(
  actionType =>
    [AuthActionTypes.DONE].includes(actionType) ||
    actionType.endsWith('Success'),
  action =>
    actions.status({
      level: 'success',
      code: action.actionName || action.type,
    }),
);

export const statusError = onAction(
  actionType =>
    [AuthActionTypes.ERROR].includes(actionType) ||
    actionType.endsWith('Error'),
  action =>
    actions.status({
      level: 'error',
      code: action.actionName || action.type,
    }),
);
