import http from '../services/http-service';
import { NewAccount } from '../account/account-model';

class AuthApiService {
  public sendPasswordResetEmail(email: string) {
    return http.post('users/password', { email });
  }

  public resetPassword(token: string, password: string) {
    return http.post(`users/password/${token}`, { password });
  }

  public signIn(email: string, password: string) {
    return http.post('users/authenticate', { email, password });
  }

  public signOut(token: string) {
    return http.delete(`users/authenticate/${token}`);
  }

  public signUp(account: NewAccount) {
    return http.post('accounts', account);
  }

  public completeSignUp(token: string, tokenId: string, password: string) {
    return http.post(`users/complete/${token}`, { tokenId, password });
  }

  public getToken(tokenId: string) {
    return http.get(`tokens/${tokenId}`);
  }
}

export default AuthApiService;
