export interface Invoice {
  amount: number;
  created: string;
  currency: string;
  id: string;
}

export interface ApiKeys {
  api: {
    live: string;
    test: string;
  };
  sdk: {
    live: string;
    test: string;
  };
}

export function transformToApiKeys(apiKeys: any): ApiKeys {
  const keys: any = {};
  apiKeys.items.forEach(({ type, id }) => {
    keys[type] = `${type}_${id}`;
  });

  return {
    api: { live: keys.sk_live, test: keys.sk_test },
    sdk: { live: keys.pk_live, test: keys.pk_test },
  };
}

export interface AccountDetails {
  amex: boolean;
  address: string;
  apiVersion: string;
  city: string;
  countryCode: string;
  id: string;
  invoiceType: string;
  liveActive: boolean;
  liveAgreement: boolean;
  name: string;
  postCode: string;
  review: boolean;
  title: string;
  vatNumber: string;
}

export interface PaymentMethod {
  cardId: string;
  isDefault: boolean;
  expYear: number;
  expMonth: number;
  lastNumbers: string;
  scheme: string;
}

export function transformToPaymentMethod(data: any): PaymentMethod {
  const paymentMethod: Partial<PaymentMethod> = {};

  Object.entries(data).forEach(([key, value]: any) => {
    if (key === 'schema') paymentMethod.scheme = value;
    else if (key === 'default') paymentMethod.isDefault = value;
    else paymentMethod[key] = value;
  });

  return paymentMethod as PaymentMethod;
}

export interface NewAccount {
  nameFirst: string;
  nameLast: string;
  companyName: string;
  email: string;
  password: string;
}
