import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import liveService from '../services/live-service';

const initialState = liveService.isLive();

const liveSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {
    setLive(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { actions } = liveSlice;
export default liveSlice.reducer;
