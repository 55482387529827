const environments = {
  clo: {
    keys: {
      prod: 'merchants-dashboard_e697144c-25f3-11e8-b467-0ed5f89f718b',
      dev: 'merchants-dashboard_463e3b0e-ac94-489a-9d38-c5a9d6543be9',
    },
    tokenName: 'Fidel-Auth-Token-Merchant',
    account: 'Fidel-Account-Merchant',
    user: 'Fidel-User-Merchant',
  },
  dashboard: {
    keys: {
      prod: 'dashboard_e33fe6a3-293f-4e64-a35b-9fe942c0824b',
      dev: 'dashboard_d256967e-e22a-4947-9928-cd77e27d3d40',
    },
    tokenName: 'Fidel-Auth-Token-Dashboard',
    account: 'Fidel-Account-Dashboard',
    user: 'Fidel-User-Merchant',
  },
};

const env = environments[process.env.REACT_APP_STORE_APP || 'clo'];

const FIDEL_KEY = env.keys.dev;
const FIDEL_KEY_PROD = env.keys.prod;
const FIDEL_AUTH_TOKEN = env.tokenName;
const FIDEL_ACCOUNT = env.account;
const FIDEL_USER = env.user;

const DEV_ENV = {
  API_PREFIX: 'https://api-dev.fidel.uk/v1d/',
  FIDEL_KEY,
  STRIPE_KEY: 'pk_test_mADjGB6PjZCqyvAiRjT3qJBN',
};

const MOCK_ENV = {
  API_PREFIX:
    'http://ec2-34-242-152-233.eu-west-1.compute.amazonaws.com:5555/v1d/',
  FIDEL_KEY,
  STRIPE_KEY: 'pk_test_mADjGB6PjZCqyvAiRjT3qJBN',
};

const PROD_ENV = {
  API_PREFIX: 'https://api.fidel.uk/v1/',
  FIDEL_KEY: FIDEL_KEY_PROD,
  STRIPE_KEY: 'pk_live_8sBfjJ8o5skRkXptXx0LH8K2',
};

const environment =
  process.env.REACT_APP_STORE_ENV === 'production'
    ? PROD_ENV
    : process.env.REACT_APP_STORE_ENV === 'mock'
    ? MOCK_ENV
    : DEV_ENV;

const config = {
  API_PREFIX: `${environment.API_PREFIX}`,
  FIDEL_KEY: `${environment.FIDEL_KEY}`,
  STRIPE_KEY: `${environment.STRIPE_KEY}`,
  FIDEL_ACCOUNT,
  FIDEL_AUTH_TOKEN,
  FIDEL_USER,
  APP_NAME: process.env.REACT_APP_NAME,
  APP_VERSION: process.env.REACT_APP_VERSION,
  PLATFORM: process.env.REACT_APP_STORE_APP || 'clo',
  MAX_BRANDS_COUNT: 10000,
  MAX_OFFERS_COUNT: 10000,
  MAX_TRANSACTION_COUNT: 10000,
  MAX_CARDS_COUNT: 10000,
  MAX_LOCATIONS_COUNT: 10000,
  MAX_ACCOUNTS_COUNT: 10000,
  MAX_WEBHOOKS_COUNT: 10000,
};

export default config;
