export default {
  BOOTSTRAP: 'BOOTSTRAP',
  COMPLETE_SIGN_UP: 'COMPLETE_SIGN_UP',
  DONE: 'AUTH_DONE',
  ERROR: 'AUTH_ERROR',
  GET_TOKEN: 'GET_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SEND_PASSWORD_RESET_EMAIL: 'SEND_PASSWORD_RESET_EMAIL',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_UP: 'SIGN_UP',
};
