import CardsApiService from './cards-api-service';

import { CardsOptions } from './cards-types';
import { actions } from './cards-reducer';

const cardsService = new CardsApiService();

export const { clear } = actions;

export const getCards = (cardsOptions: CardsOptions) => async dispatch => {
  dispatch(actions.getCards());

  const isLoadMore = !!cardsOptions.last;

  if (isLoadMore) dispatch(actions.getMoreCards());
  else dispatch(actions.getCards());

  try {
    const response = await cardsService.getCards(cardsOptions);
    const { items, last } = response.data;
    if (isLoadMore) dispatch(actions.getMoreCardsSuccess({ items, last }));
    else dispatch(actions.getCardsSuccess({ items, last }));
  } catch (error) {
    if (isLoadMore) dispatch(actions.getMoreCardsError({ error }));
    else dispatch(actions.getCardsError({ error }));
  }
};

export const getCardsCount = (cardsOptions: CardsOptions) => async dispatch => {
  dispatch(actions.getCardsCount());

  try {
    const count = await cardsService.getCardsCount(cardsOptions);
    dispatch(actions.getCardsCountSuccess(count));
  } catch (error) {
    dispatch(actions.getCardsCountError({ error }));
  }
};

export const getExportableCards = (options: CardsOptions) => async dispatch => {
  dispatch(actions.getExportableCards());

  try {
    const cards = await cardsService.getAllCards(options);
    dispatch(actions.getExportableCardsSuccess({ cards }));
  } catch (error) {
    dispatch(actions.getExportableCardsError({ error }));
  }
};
