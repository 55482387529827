import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiKeys,
  PaymentMethod,
  AccountDetails,
  transformToApiKeys,
  transformToPaymentMethod,
  Invoice,
} from './account-model';
import { transformToStatus } from '../status/status-model';

export interface AccountState {
  error?: any;
  loading: boolean;
  goingLive: boolean;
  addingPaymentMethod: boolean;
  deletingPaymentMethod: boolean;
  settingDefaultPaymentMethod: boolean;
  enablingAmex: boolean;
  amexEnableSuccess?: boolean;
  apiKeys?: ApiKeys;
  paymentMethods?: PaymentMethod[];
  invoices?: Invoice[];
  version?: any;
  details?: AccountDetails;
}

const initialState: AccountState = {
  loading: false,
  goingLive: false,
  addingPaymentMethod: false,
  deletingPaymentMethod: false,
  settingDefaultPaymentMethod: false,
  enablingAmex: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clear: () => initialState,
    getAccount(state) {
      state.loading = true;
    },
    getAccountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.details = action.payload;
    },
    getAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = transformToStatus(
        action.payload.response?.data?.error || {
          code: 'error',
          message: 'Unexpected error',
        },
      );
    },

    getAllAccountInformation(state) {
      state.loading = true;
    },
    getAllAccountInformationSuccess(state, action: PayloadAction<any>) {
      state.loading = false;

      // eslint-disable-next-line prefer-destructuring
      state.version = action.payload.versions.items[0];
      state.apiKeys = transformToApiKeys(action.payload.apiKeys);
      state.invoices = action.payload.invoices.items;
      state.paymentMethods = action.payload.paymentMethods.items.map(
        transformToPaymentMethod,
      );
    },
    getAllAccountInformationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = transformToStatus(
        action.payload.response?.data?.error || {
          code: 'error',
          message: 'Unexpected error',
        },
      );
    },

    getPaymentMethods(state) {
      state.loading = true;
    },
    getPaymentMethodsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.paymentMethods = action.payload.items.map(transformToPaymentMethod);
    },
    getPaymentMethodsError(state) {
      state.loading = false;
    },

    addPaymentMethod(state) {
      state.addingPaymentMethod = true;
    },
    addPaymentMethodSuccess(state) {
      state.addingPaymentMethod = false;
    },
    addPaymentMethodError(state) {
      state.addingPaymentMethod = false;
    },

    deletePaymentMethod(state) {
      state.deletingPaymentMethod = true;
    },
    deletePaymentMethodSuccess(state) {
      state.deletingPaymentMethod = false;
    },
    deletePaymentMethodError(state) {
      state.deletingPaymentMethod = false;
    },

    setDefaultPaymentMethod(state) {
      state.settingDefaultPaymentMethod = true;
    },
    setDefaultPaymentMethodSuccess(state) {
      state.settingDefaultPaymentMethod = false;
    },
    setDefaultPaymentMethodError(state) {
      state.settingDefaultPaymentMethod = false;
    },

    enableAmex(state) {
      state.enablingAmex = true;
    },
    enableAmexSuccess(state) {
      state.amexEnableSuccess = true;
      state.enablingAmex = false;
    },
    enableAmexFailure(state) {
      state.amexEnableSuccess = false;
      state.enablingAmex = false;
    },

    goLive(state) {
      state.goingLive = true;
    },
    goLiveSuccess(state) {
      state.goingLive = false;
    },
    goLiveError(state) {
      state.goingLive = false;
    },
  },
});

export const { actions } = accountSlice;
export default accountSlice.reducer;
