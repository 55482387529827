import jwtDecode from 'jwt-decode';
import http from '../services/http-service';

async function extractData(response: Promise<any>) {
  return (await response).data;
}

export default class AccountApiService {
  public enableAmex(accountId: string) {
    return http.patch(`accounts/${accountId}/product`, { product: 'amex' });
  }

  public getAccount(tokenOrId: string) {
    /* When fetching for the first time, getAccount consumes a token.
     * Later, we can simply refetch account info using the accountId. */
    let accountId: string;
    try {
      const decodedToken = jwtDecode(tokenOrId);
      accountId = decodedToken?.permission?.accountId;
    } catch (e) {
      /* If it’s not a decodable token, it may be an accountId. */
      accountId = tokenOrId;
    }

    return http.get(`accounts/${accountId}`);
  }

  public async getAllAccountInformation(accountId: string) {
    const [apiKeys, invoices, paymentMethods, versions] = await Promise.all([
      extractData(this.getApiKeys()),
      extractData(this.getAllInvoices()),
      extractData(this.getPaymentMethods(accountId)),
      extractData(this.getVersions()),
    ]);

    return { apiKeys, invoices, paymentMethods, versions };
  }

  public getApiKeys() {
    return http.get('credentials');
  }

  public async getAllInvoices() {
    let response = await this.getInvoices();
    let { items } = response.data;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getInvoices({ last: response.data.last });
      items = [...items, ...response.data.items];
    }

    return { data: { items } };
  }

  public getVersions() {
    return http.get('versions', { params: { order: 'desc', limit: 1 } });
  }

  public getPaymentMethods(accountId: string) {
    return http.get(`accounts/${accountId}/payment`);
  }

  public addPaymentMethod(accountId: string, stripeToken: string) {
    return http.post(`accounts/${accountId}/payment`, {
      stripeToken,
      setAsDefault: true,
    });
  }

  public deletePaymentMethod(accountId: string, cardId: string) {
    return http.delete(`accounts/${accountId}/payment/${cardId}`);
  }

  public setDefaultPaymentMethod(accountId: string, cardId: string) {
    return http.patch(`accounts/${accountId}/payment/${cardId}`, {
      setAsDefault: true,
    });
  }

  private getInvoices({ last }: any = {}) {
    let params: any = { projection: 'id,created,amount,currency' };
    if (last) params = { ...params, start: JSON.stringify(last) };

    return http.get('invoices', { params });
  }

  public goLive(accountId: string, data: any) {
    return http.post(`accounts/${accountId}/live`, data);
  }
}
