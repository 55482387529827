import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';

import sendErrorsToSentry from './reporting/send-to-sentry';
import { statusDone, statusError } from './status/status-actions';
import { createActionListenerMiddleware } from './utils/action-listen-middleware';
import account from './account/account-reducer';
import { authReducer } from './auth/auth-reducer';
import brands from './brands/brand-reducer';
import cards from './cards/cards-reducer';
import live from './live/live-reducer';
import locations from './locations/locations-reducer';
import offer from './offers/current-offer/current-offer-reducer';
import offers from './offers/offer-reducer';
import programs from './programs/program-reducer';
import status from './status/status-reducer';
import transactions from './transactions/transaction-reducer';
import moderator from './moderator/moderator-reducer';
import user from './user/user-reducer';
import webhooks from './webhooks/webhooks-reducer';
import brandUser from './brand-user/brand-user-reducer';

const reducer = combineReducers({
  account,
  authReducer,
  brands,
  brandUser,
  cards,
  offers,
  offer,
  live,
  locations,
  moderator,
  programs,
  status,
  transactions,
  user,
  webhooks,
});

export type State = ReturnType<typeof reducer>;
export default function createStore() {
  const actionListeners = [statusDone, statusError, sendErrorsToSentry];
  const actionListenerMiddleware = createActionListenerMiddleware(
    actionListeners,
  );

  const inTestRun = typeof jest !== 'undefined';

  const middleware = getDefaultMiddleware({
    thunk: true,
    serializableCheck: inTestRun,
    immutableCheck: inTestRun,
  }).concat(actionListenerMiddleware);

  return configureStore({
    reducer,
    middleware,
  });
}
