import UserApiService from './user-api-service';
import config from '../utils/config';
import { getOffers } from '../offers/offer-actions';
import { getAccount } from '../account/account-actions';
import { actions as brandUserActions } from '../brand-user/brand-user-reducer';
import { transformToToken } from '../auth/auth-token';
import { actions } from './user-reducer';

const userApiService = new UserApiService();

export const getUser = (encodedToken: string) => dispatch => {
  const token = transformToToken(encodedToken);
  dispatch(getUserFromIds(token.userId, token.accountId));
};

export const getUserFromIds = (
  userId: string,
  accountId?: string,
) => async dispatch => {
  const dispatchOn = (platform: string, action: any) => {
    if (config.PLATFORM === platform) dispatch(action);
  };

  if (accountId) localStorage.setItem(config.FIDEL_ACCOUNT, accountId);
  else localStorage.removeItem(config.FIDEL_ACCOUNT);

  dispatch(actions.getUser());
  dispatchOn('clo', brandUserActions.clear());
  dispatchOn('clo', brandUserActions.getUser());

  try {
    const response = await userApiService.getUser(userId);
    const user = response.data.items[0];
    dispatch(getUserFromData(user));
    if (accountId) {
      dispatchOn('dashboard', getAccount(accountId));
    }
  } catch (e) {
    dispatch(actions.getUserError(e));
    dispatchOn('clo', brandUserActions.getUserError(e));
  }
};

export const getUserFromData = (data: any, loading?: boolean) => dispatch => {
  const dispatchOn = (platform: string, action: any) => {
    if (config.PLATFORM === platform) dispatch(action);
  };

  if (loading) {
    dispatch(actions.getUser());
    localStorage.setItem(config.FIDEL_ACCOUNT, data.accountId);
  }

  if (config.PLATFORM === 'clo')
    localStorage.setItem(config.FIDEL_USER, data.id);

  dispatch(actions.getUserSuccess(data));

  dispatchOn('clo', getOffers());
  dispatchOn('clo', brandUserActions.getUserSuccess(data));
};

export const { clear } = actions;
