import { withScope, captureException } from '@sentry/browser';
import { State } from '..';
import { onAction } from '../utils/action-listen-middleware';
import AuthActionTypes from '../auth/auth-action-types';

const sendActionToSentry = action => (dispatch, getState) => {
  const state: State = getState();

  withScope(scope => {
    scope.setExtra('actionName', action.actionName || action.type);
    scope.setExtra('Error', action.error || action.payload);
    if (state.user.details) {
      scope.setUser({
        email: state.user.details.email,
        username: `${state.user.details.nameFirst} ${state.user.details.nameLast}`,
        id: state.user.details.id,
      });
      scope.setTag('fidel-lang', localStorage.getItem('fidel-lang') || 'en');
    }
    captureException(new Error(action.actionName || action.type));
  });
};

export default onAction(
  actionType =>
    [AuthActionTypes.ERROR].includes(actionType) ||
    (actionType.endsWith('Error') && actionType !== 'clearError'),
  sendActionToSentry,
);
