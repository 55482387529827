import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Level = 'info' | 'success' | 'error';

export interface Status {
  level: Level;
  code?: string;
  message?: string;
  values?: Record<string, any>;
}

export interface StatusState {
  counter: number;
  status: Status | null;
}

const initialState: StatusState = {
  status: null,
  counter: 0,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    clear(state) {
      state.status = null;
    },
    status(state, action: PayloadAction<Status>) {
      state.counter++;
      state.status = action.payload;
    },
  },
});

export default statusSlice.reducer;
export const { actions } = statusSlice;
