import moment, { Moment } from 'moment';

export interface Invoices {
  email?: string;
  upcoming: Invoice | null;
  history: Invoice[];
}

export interface Invoice {
  date: number;
  startDate: number;
  amount: number;
  currency: string;
  pdfLink: string;
  dueDate: Moment;
  status: string;
  performanceFee: { amount: number; vat: number };
  cashback: { amount: number; vat: number };
  identifier?: string;
}

export function transformToInvoices(data: any) {
  return {
    upcoming: data.upcoming ? transformToInvoice(data.upcoming) : null,
    email: data.email,
    history: data.history.map(transformToInvoice),
  };
}

export function transformToInvoice(data: any): Invoice {
  const invoice: Partial<Invoice> = {
    date: data.date,
    startDate: data.startDate,
    currency: data.currency,
    pdfLink: data.pdfLink,
    identifier: data.identifier,
  };

  invoice.performanceFee = (data.items &&
    data.items.find(d => d.type === 'PERFORMANCE_FEE')) || {
    amount: 0,
    vat: 0,
  };

  invoice.cashback = (data.items &&
    data.items.find(d => d.type === 'CASHBACK')) || { amount: 0, vat: 0 };

  invoice.dueDate = moment.unix(invoice.date).add(2, 'w');

  invoice.status = data.paid
    ? 'paid'
    : moment().diff(invoice.dueDate, 'days') <= 0
    ? 'pending'
    : 'overdue';

  invoice.amount =
    data.amount ||
    (invoice.performanceFee &&
      invoice.cashback &&
      invoice.performanceFee.amount * (1 + invoice.performanceFee.vat) +
        invoice.cashback.amount * (1 + invoice.cashback.vat));

  return invoice as Invoice;
}

export interface Program {
  id: string;
  brandId: string;
  name: string;
  programId: string;
  pairId: string;
}

export function transformToProgram(data: any): Program {
  return {
    id: data.id,
    name: data.name,
    brandId: data.brands[0].id,
    programId: data.id,
    pairId: `${data.brands[0].id}_${data.id}`,
  };
}

export function transformToPrograms(programs: any): Program[] {
  return programs
    ? programs
        .filter(
          program => program && program.brands && program.brands.length > 0,
        )
        .map(transformToProgram)
    : [];
}

export interface MerchantAccount {
  email: string;
  brandLogoUrl: string;
  brandName: string;
  id: string;
  name: string;
  initials: any;
}

export function transformToMerchantAccount({
  brandName,
  programs,
  email,
  nameLast,
  nameFirst,
  accountId,
}: any): MerchantAccount {
  const brand = !!programs && programs.length >= 1 && programs[0].brands![0];
  return {
    email,
    name: `${nameFirst} ${nameLast}`,
    initials: `${nameFirst[0]}${nameLast[0]}`,
    brandName: (brand && brand.name) || brandName,
    brandLogoUrl: (brand && brand.logoUrl) || undefined,
    id: accountId,
  };
}

export function transformToBusinessInfo(data) {
  return {
    address: data.address,
    businessType: data.businessType,
    city: data.city,
    companyName: data.companyName,
    companyNumber: data.companyNumber,
    countryCode: data.countryCode,
    description: data.description,
    postcode: data.postcode,
    vatNumber: data.vatNumber,
  };
}

export interface BusinessInformation {
  address: string;
  businessType: string;
  city: string;
  companyName: string;
  companyNumber: string;
  countryCode: string;
  description: string;
  postcode: string;
  vatNumber: string;
}

export interface Source {
  last4: string;
  expMonth: string;
  expYear: string;
  brand: string;
}

export interface StripeInformation {
  customer: string;
  defaultSource: Source;
  mode: string;
}

export function transformToStripeInformation(data: any): StripeInformation {
  return {
    customer: data.customer,
    defaultSource: data.defaultSource && {
      last4: data.defaultSource.last4,
      expMonth: data.defaultSource.exp_month,
      expYear: data.defaultSource.exp_year,
      brand: !data.defaultSource.brand.includes('American Express')
        ? data.defaultSource.brand.toLowerCase()
        : 'amex',
    },
    mode: data.mode,
  };
}

export interface Publisher {
  id: string;
  name: string;
}

export function transformToPublisher(data: any): Publisher {
  return {
    id: data.accountId,
    name: data.accountName,
  };
}

export interface BrandUserDetails {
  stepsCompleted?: any;
  stripe?: StripeInformation;
  gocardless?: any;
  brandId?: string;
  brandLogoUrl?: string;
  publisher?: Publisher;
}

export function transformToBrandUserDetails(data: any): BrandUserDetails {
  const result: Partial<BrandUserDetails> = {};

  result.stepsCompleted = data.stepsCompleted;

  result.gocardless = data.gocardless;

  if (data.stripe) {
    result.stripe = transformToStripeInformation(data.stripe);
  }

  const { programs } = data;
  const hasPrograms = programs && programs[0];
  result.brandId =
    hasPrograms && programs[0].brands![0] ? programs[0].brands![0].id : '';
  result.brandLogoUrl =
    hasPrograms && programs[0].brands![0] ? programs[0].brands![0].logoURL : '';

  if (hasPrograms) {
    result.publisher = transformToPublisher(programs[0]);
  }

  return result as BrandUserDetails;
}
