import liveService from '../services/live-service';
import { actions } from './live-reducer';
import * as BrandsActions from '../brands/brand-actions';
import * as LocationsActions from '../locations/locations-actions';
import * as ProgramsActions from '../programs/program-actions';
import * as OfferActions from '../offers';
import * as TransactionsActions from '../transactions/transaction-actions';

export const setLive = (live: boolean) => dispatch => {
  liveService.setLive(live);
  dispatch(actions.setLive(live));

  dispatch(ProgramsActions.clear());
  dispatch(LocationsActions.clear());
  dispatch(BrandsActions.clear());
  dispatch(OfferActions.clear());
  dispatch(OfferActions.clearOffer());
  dispatch(TransactionsActions.clear());
};
