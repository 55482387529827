import config from '../utils/config';
import http from '../services/http-service';
import { LocationsFilters } from './locations-reducer';

export interface UpdateLocationPayload {
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
  stateCode?: string;
  searchBy?: {
    merchantIds?: {
      visa?: string[];
      mastercard?: string[];
    };
  };
}

export interface CreateLocationPayload extends UpdateLocationPayload {
  brandId: string;
}

export interface LocationsOptions {
  programId: string;
  filters?: LocationsFilters;
  last?: any;
}

class LocationsApiService {
  private rawGetLocations({ programId, filters, last }: LocationsOptions) {
    const url = filters?.brandId
      ? `brands/${filters.brandId}/programs/${programId}/locations`
      : `programs/${programId}/locations`;

    const params: any = { expand: 'brandId', ...filters };

    // brandId is special filter and appears in path not in query
    delete params.brandId;

    if (last) params.start = JSON.stringify(last);

    return http.get(url, { params });
  }

  private rawGetLocationsCount(programId: string, last?: any) {
    const url = `programs/${programId}/locations`;
    const params: any = {
      select: 'count',
    };

    if (last) params.start = JSON.stringify(last);

    return http.get(url, { params });
  }

  public async getLocationsCount(programId: string) {
    let response = await this.rawGetLocationsCount(programId);
    let { count } = response.data;

    while (response.data.last && count < config.MAX_LOCATIONS_COUNT) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.rawGetLocationsCount(programId, response.data.last);
      count += response.data.count;
    }

    return count;
  }

  public async getAllLocations({ programId, filters, last }: LocationsOptions) {
    let response = await this.rawGetLocations({ programId, filters, last });
    let allLocations = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.rawGetLocations({
        programId,
        filters,
        last: response.data.last,
      });
      allLocations = [...allLocations, ...response.data.items];
    }

    return allLocations;
  }

  public async getLocations({ programId, filters, last }: LocationsOptions) {
    return this.rawGetLocations({ programId, filters, last });
  }

  public async createLocation(
    programId: string,
    location: CreateLocationPayload,
  ) {
    return http.post(`programs/${programId}/locations`, location);
  }

  public async deleteLocation(locationId: string) {
    return http.delete(`locations/${locationId}`);
  }

  public async updateLocation(
    locationId: string,
    location: UpdateLocationPayload,
  ) {
    return http.patch(`locations/${locationId}`, location);
  }
}

export default LocationsApiService;
