import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WebhooksState {
  items: any[];
  last?: any;
  error?: any;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  count: number;
}

const initialState: WebhooksState = {
  items: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  count: 0,
};

const webhooksSlice = createSlice({
  name: 'webhooks',
  initialState,
  reducers: {
    clear: () => initialState,

    getWebhooks(state) {
      state.items = [];
      state.loading = true;
    },
    getWebhooksSuccess(state, action: PayloadAction<any>) {
      const { items, last } = action.payload;

      state.loading = false;
      state.items = items;
      state.last = last;
    },
    getWebhooksError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    getMoreWebhooks(state) {
      state.loading = true;
    },
    getMoreWebhooksSuccess(state, action: PayloadAction<any>) {
      const { items, last } = action.payload;

      state.loading = false;
      state.last = last;
      state.items.push(...items);
    },
    getMoreWebhooksError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    createWebhook(state) {
      state.creating = true;
    },
    createWebhookSuccess(state) {
      state.creating = false;
    },
    createWebhookError(state) {
      state.creating = false;
    },

    updateWebhook(state) {
      state.updating = true;
    },
    updateWebhookSuccess(state) {
      state.updating = false;
    },
    updateWebhookError(state) {
      state.updating = false;
    },

    deleteWebhook(state) {
      state.deleting = true;
    },
    deleteWebhookSuccess(state) {
      state.deleting = false;
    },
    deleteWebhookError(state) {
      state.deleting = false;
    },

    getWebhooksCount(state) {
      state.loading = true;
    },
    getWebhooksCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.count = action.payload || 0;
    },
    getWebhooksCountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions } = webhooksSlice;
export default webhooksSlice.reducer;
