import * as OfferActions from './offers';
import * as TransactionActions from './transactions/transaction-actions';
import * as UserActions from './user/user-actions';
import * as ProgramActions from './programs/program-actions';
import * as AccountActions from './account/account-actions';
import * as BrandUserActions from './brand-user/brand-user-actions';
import * as LocationActions from './locations/locations-actions';

const clearAll = () => dispatch => {
  dispatch(OfferActions.clear());
  dispatch(OfferActions.clearOffer());
  dispatch(TransactionActions.clear());
  dispatch(UserActions.clear());
  dispatch(ProgramActions.clear());
  dispatch(AccountActions.clear());
  dispatch(BrandUserActions.clear());
  dispatch(LocationActions.clear());
};

export default clearAll;
