import ProgramApiService from './program-api-service';
import { getLocations } from '../locations/locations-actions';
import { actions } from './program-reducer';

const programApiService: ProgramApiService = new ProgramApiService();

export const { clear } = actions;

export const getProgram = (programId: string) => async dispatch => {
  dispatch(actions.getProgram());

  try {
    const response = await programApiService.getProgram(programId);
    dispatch(actions.getProgramSuccess(response.data));
  } catch (error) {
    dispatch(actions.getProgramError({ error }));
  }
};

export const getPrograms = (options?: any) => async dispatch => {
  dispatch(actions.getPrograms());

  try {
    const response = await programApiService.getPrograms(options);
    dispatch(actions.getProgramsSuccess(response.data));
  } catch (error) {
    dispatch(actions.getProgramsError({ error }));
  }
};

export const createProgram = (name: string) => async dispatch => {
  dispatch(actions.createProgram());

  try {
    await programApiService.createProgram(name);
    dispatch(actions.createProgramSuccess());
  } catch (error) {
    dispatch(actions.createProgramError({ error }));
  }
};

export const updateProgram = (
  programId: string,
  name: string,
) => async dispatch => {
  dispatch(actions.updateProgram());

  try {
    await programApiService.updateProgram(programId, name);
    dispatch(actions.updateProgramSuccess());
  } catch (error) {
    dispatch(actions.updateProgramError({ error }));
  }
};

export const syncProgram = (programId: string) => async dispatch => {
  dispatch(actions.syncProgram());
  try {
    const response = await programApiService.syncProgram(programId);
    dispatch(actions.syncProgramSuccess(response.data));
    dispatch(getLocations(programId));
  } catch (error) {
    dispatch(actions.syncProgramError({ error }));
  }
};
