import { config } from '..';
import http from '../services/http-service';
import { UsersOptions } from './moderator-model';

class ModeratorApiService {
  public async getAccountsCount() {
    const fetch = (last?: any) => {
      const params: any = { select: 'count' };
      if (last) params.start = JSON.stringify(last);
      return http.get('accounts', { params });
    };

    let response = await fetch();
    let { count } = response.data as { count: number };

    while (response.data.last && count < config.MAX_BRANDS_COUNT) {
      const { last } = response.data;
      // eslint-disable-next-line no-await-in-loop
      response = await fetch(last);
      count += response.data.count;
    }

    return count;
  }

  public getAccounts(last?: any) {
    return http.get('accounts', {
      params: {
        expand: 'userId',
        start: last && JSON.stringify(last),
      },
    });
  }

  async getAllAccounts() {
    let response = await this.getAccounts();
    let allAccounts = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getAccounts(response.data.last);
      allAccounts = [...allAccounts, ...response.data.items];
    }

    return allAccounts;
  }

  public getUsers({ account, brand, last }: UsersOptions) {
    const params: any = {
      expand: 'userId,programId,accountId',
    };

    if (account) params.account = account;
    if (brand) params.brand = brand;
    if (last) params.start = JSON.stringify(last);

    const headers = { 'fidel-version': '2019-03-05' };

    return http.get(`brands-users`, { params, headers });
  }

  async getAllUsers(options: UsersOptions = {}) {
    let response = await this.getUsers(options);
    let allUsers = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getUsers({ ...options, last: response.data.last });
      allUsers = [...allUsers, ...response.data.items];
    }

    return allUsers;
  }

  public reviewAccount(accountId: string, review: boolean, message?: string) {
    return http.patch(`accounts/${accountId}/review`, {
      review,
      message,
    });
  }
}

export default ModeratorApiService;
