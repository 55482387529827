import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Offer, transformToOffer } from '../offer-model';

type LocationsStatus = { loading: boolean; items?: any[] };

export interface CurrentOfferState {
  createdOrUpdated: boolean;
  loading: boolean;
  locations: Record<string, { loading: boolean; items?: any[]; error?: any }>;
  locationsStatus?: {
    linked?: LocationsStatus;
    unlinked?: LocationsStatus;
  };
  paymentCompleted: boolean;
  rejected: boolean;
  saving: boolean;
  error?: any;
  offer?: Offer;
}

const initialState: CurrentOfferState = {
  createdOrUpdated: false,
  loading: false,
  locations: {},
  paymentCompleted: false,
  rejected: false,
  saving: false,
};

const currentOfferSlice = createSlice({
  name: 'currentOffer',
  initialState,
  reducers: {
    clear: () => initialState,
    clearError(state) {
      state.error = undefined;
    },
    createOffer(state) {
      state.loading = true;
      state.createdOrUpdated = false;
      state.error = undefined;
    },
    createOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createdOrUpdated = true;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    createOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    deleteOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    deleteOfferSuccess(state) {
      state.loading = false;
    },
    deleteOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    getOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    getOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    getAllLocations(state, action: PayloadAction<any>) {
      state.loading = true;
      state.locations = {
        ...state.locations,
        [action.payload]: { loading: true },
      };
    },
    getAllLocationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locations = {
        ...state.locations,
        [action.payload.offerId]: {
          loading: false,
          items: action.payload.items,
        },
      };
    },
    getAllLocationsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locations = {
        ...state.locations,
        [action.payload.offerId]: {
          loading: false,
          error: action.payload.error,
        },
      };
    },
    linkCardToOffer(state) {
      state.loading = true;
      state.error = undefined;
    },
    linkCardToOfferSuccess(state) {
      state.loading = false;
    },
    linkCardToOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    linkLocation(state) {
      state.loading = true;
      state.error = undefined;
    },
    linkLocationSuccess(state) {
      state.loading = false;
    },
    linkLocationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    linkLocations(state) {
      state.loading = true;
      state.locationsStatus = { linked: { loading: true, items: [] } };
    },
    linkLocationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locationsStatus = {
        linked: { loading: false, items: action.payload },
      };
    },
    processPayment(state) {
      state.error = undefined;
      state.loading = true;
      state.paymentCompleted = false;
    },
    processPaymentSuccess(state) {
      state.loading = false;
      state.paymentCompleted = true;
    },
    processPaymentError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    rejectOffer(state) {
      state.error = undefined;
      state.loading = true;
      state.rejected = false;
    },
    rejectOfferSuccess(state) {
      state.loading = false;
      state.rejected = true;
    },
    rejectOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    unlinkLocation(state) {
      state.error = undefined;
      state.loading = true;
    },
    unlinkLocationSuccess(state) {
      state.loading = false;
    },
    unlinkLocationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
    unlinkLocations(state) {
      state.loading = true;
      state.locationsStatus = { unlinked: { loading: true, items: [] } };
    },
    unlinkLocationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.locationsStatus = {
        unlinked: { loading: false, items: action.payload },
      };
    },
    updateOffer(state) {
      state.loading = true;
      state.createdOrUpdated = false;
      state.error = undefined;
    },
    updateOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createdOrUpdated = true;
      state.offer = transformToOffer(action.payload.items[0]);
    },
    updateOfferError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export default currentOfferSlice.reducer;
export const { actions } = currentOfferSlice;
