import axios, { AxiosRequestConfig } from 'axios';

import config from '../utils/config';
import liveService from './live-service';

export const requestInterceptor = axios.interceptors.request;
export const responseInterceptor = axios.interceptors.response;

type requestInterceptorArgs = Parameters<typeof axios.interceptors.request.use>;
type responseInterceptorArgs = Parameters<
  typeof axios.interceptors.response.use
>;

export class HttpService {
  private baseUrl: string = config.API_PREFIX;

  constructor() {
    this.addRequestInterceptor(req => {
      req.headers = {
        ...req.headers,
        'Content-Type': 'application/json',
        'fidel-key': config.FIDEL_KEY,
        'fidel-live': config.PLATFORM === 'clo' || liveService.isLive(),
      };

      const account = localStorage.getItem(config.FIDEL_ACCOUNT);

      if (account) req.headers['fidel-account'] = account;

      // TODO: Remove when CLO uses Cognito or implements this as an interceptor
      if (config.PLATFORM === 'clo') {
        const token = localStorage.getItem(config.FIDEL_AUTH_TOKEN);
        if (token) req.headers.Authorization = `Bearer ${token}`;
      }

      return req;
    });
  }

  addRequestInterceptor(...args: requestInterceptorArgs) {
    const interceptor = axios.interceptors.request.use(...args);
    return () => axios.interceptors.request.eject(interceptor);
  }

  addResponseInterceptor(...args: responseInterceptorArgs) {
    const interceptor = axios.interceptors.response.use(...args);
    return () => axios.interceptors.response.eject(interceptor);
  }

  get(url: string, options: AxiosRequestConfig = {}) {
    return axios.get(`${this.baseUrl}${url}`, options);
  }

  post(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.post(`${this.baseUrl}${url}`, data, options);
  }

  put(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.put(`${this.baseUrl}${url}`, data, options);
  }

  patch(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.patch(`${this.baseUrl}${url}`, data, options);
  }

  delete(url: string) {
    return axios.delete(`${this.baseUrl}${url}`, {
      data: { workaround: 'content-type in axios' },
    });
  }
}

export default new HttpService();
