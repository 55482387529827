import OfferApiService from './offer-api-service';
import { actions } from './offer-reducer';
import { OfferType } from './offer-model';

const offerService: OfferApiService = new OfferApiService();

export const { clear } = actions;

export const getAllOffers = () => async dispatch => {
  dispatch(actions.getAllOffers());

  try {
    const data = await offerService.getAllOffers();
    dispatch(actions.getAllOffersSuccess(data));
  } catch (error) {
    dispatch(actions.getAllOffersError({ error }));
  }
};

export const getOffers = () => async dispatch => {
  dispatch(actions.getOffers());

  try {
    const data = await offerService.getOffers();
    dispatch(actions.getOffersSuccess(data));
  } catch (error) {
    dispatch(actions.getOffersError({ error }));
  }
};

export const getOffersCount = () => async dispatch => {
  dispatch(actions.getOffersCount());

  try {
    const data = await offerService.getOffersCount();
    dispatch(actions.getOffersCountSuccess(data));
  } catch (error) {
    dispatch(actions.getOffersCountError({ error }));
  }
};

export const getOffersByType = (
  type: OfferType,
  last?: any,
) => async dispatch => {
  if (last) dispatch(actions.getMoreOffersByType());
  else dispatch(actions.getOffersByType(type));

  try {
    const { data } = await offerService[
      `get${type.charAt(0).toUpperCase() + type.slice(1)}Offers`
    ]({ last });
    const payload = { type, ...data };
    if (last) dispatch(actions.getMoreOffersByTypeSuccess(payload));
    else dispatch(actions.getOffersByTypeSuccess(payload));
  } catch (error) {
    if (last) dispatch(actions.getMoreOffersByTypeError(error));
    else dispatch(actions.getOffersByTypeError({ error }));
  }
};
