import i18next from 'i18next';

const EnglishTranslation = require('../../lang/en.json');
const FrenchTranslation = require('../../lang/fr.json');

const LANGUAGES = ['en', 'fr'];

export default function translation() {
  const navigatorLanguage: string =
    process.env.NODE_ENV !== 'test'
      ? window &&
        window.navigator &&
        window.navigator.language &&
        window.navigator.language.substring(0, 2).toLowerCase()
      : '';
  const savedLanguage = localStorage.getItem('fidel-lang');
  const defaultLanguage =
    savedLanguage ||
    (navigatorLanguage && LANGUAGES.indexOf(navigatorLanguage) !== -1
      ? navigatorLanguage
      : 'en');

  let translate;

  const i18nService = i18next.init(
    {
      interpolation: {
        escapeValue: false,
      },
      fallbackLng: 'en',
      lng: defaultLanguage,
      resources: {
        en: {
          translation: EnglishTranslation,
        },
        fr: {
          translation: FrenchTranslation,
        },
      },
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },
    },
    (err, t) => {
      translate = t;
    },
  );

  return { translate, i18nService };
}
