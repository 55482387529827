import { AxiosPromise } from 'axios';
import moment from 'moment';
import http from '../services/http-service';

import config from '../utils/config';
import { Offer, OfferOptions, PaymentInfo, RejectInfo } from './offer-model';

async function extractData(response: AxiosPromise<any>) {
  return (await response).data;
}

export const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export default class OfferApiService {
  private async rawGetOffers({
    endDateAfterOrNull,
    endDateBefore,
    last,
    qualifying,
    select,
    startDateAfter,
    startDateBefore,
  }: OfferOptions = {}) {
    const params: any = {};
    const headers: any = {};
    const user = localStorage.getItem(config.FIDEL_USER);

    if (user) headers['fidel-user'] = user;

    if (last) params.last = last;
    if (endDateAfterOrNull) params.endDateAfterOrNull = endDateAfterOrNull;
    if (endDateBefore) params.endDateBefore = endDateBefore;
    if (qualifying !== undefined) params.qualifying = qualifying;
    if (select) params.select = select;
    if (startDateAfter) params.startDateAfter = startDateAfter;
    if (startDateBefore) params.startDateBefore = startDateBefore;

    return http.get('offers', {
      headers,
      params,
    });
  }

  private async getCount(getFn, options?: OfferOptions) {
    let last: any = null;
    let count = 0;

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await getFn({
        ...options,
        select: 'count',
        last,
      });
      count += response.data.count;
      ({ last } = response.data);
    } while (last && count < config.MAX_OFFERS_COUNT);

    return count;
  }

  public async getAllOffers(options?: OfferOptions) {
    let response = await this.rawGetOffers(options);
    let allOffers = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.rawGetOffers({
        ...options,
        last: response.data.last,
      });
      allOffers = [...allOffers, ...response.data.items];
    }

    return allOffers;
  }

  public async getOffers() {
    const [pending, allSet, live, expired] = await Promise.all([
      extractData(this.getPendingOffers()),
      extractData(this.getAllSetOffers()),
      extractData(this.getLiveOffers()),
      extractData(this.getExpiredOffers()),
    ]);

    return { pending, allSet, live, expired };
  }

  public async getOffersCount() {
    const [
      pendingCount,
      allSetCount,
      liveCount,
      expiredCount,
    ] = await Promise.all([
      this.getCount(this.getPendingOffers.bind(this)),
      this.getCount(this.getAllSetOffers.bind(this)),
      this.getCount(this.getLiveOffers.bind(this)),
      this.getCount(this.getExpiredOffers.bind(this)),
    ]);

    return { pendingCount, allSetCount, liveCount, expiredCount };
  }

  public getPendingOffers(options: OfferOptions = {}) {
    return this.rawGetOffers({ ...options, qualifying: false });
  }

  public getExpiredOffers(options: OfferOptions = {}) {
    return this.rawGetOffers({
      ...options,
      qualifying: true,
      endDateBefore: moment().format(DATE_FORMAT),
    });
  }

  public getAllSetOffers(options: OfferOptions = {}) {
    return this.rawGetOffers({
      ...options,
      qualifying: true,
      startDateAfter: moment()
        .add(1, 'minute')
        .format(DATE_FORMAT),
    });
  }

  public getLiveOffers(options: OfferOptions = {}) {
    return this.rawGetOffers({
      ...options,
      qualifying: true,
      startDateBefore: moment().format(DATE_FORMAT),
      endDateAfterOrNull: moment().format(DATE_FORMAT),
    });
  }

  public createOffer(offer: Offer) {
    return http.post('offers', offer);
  }

  public getOffer(offerId: string) {
    const headers: any = {};
    const user = localStorage.getItem(config.FIDEL_USER);

    if (user) headers['fidel-user'] = user;

    return http.get(`offers/${offerId}`, { headers });
  }

  public processPayment(brandId: string, body: PaymentInfo) {
    return http.patch(`brands/${brandId}/payment`, body);
  }

  public updateOffer(offerId: string, offer: Offer) {
    return http.patch(`offers/${offerId}`, offer);
  }

  public deleteOffer(offerId: string) {
    return http.delete(`offers/${offerId}`);
  }

  public rejectOffer(offerId: string, body: RejectInfo) {
    return http.patch(`offers/${offerId}/reject`, body);
  }

  public linkCardToOffer(cardId: string, offerId: string) {
    return http.post(`offers/${offerId}/cards/${cardId}`, {});
  }

  public linkLocation(locationId: string, offerId: string) {
    return http.post(`offers/${offerId}/locations/${locationId}`, {});
  }

  public unlinkLocation(locationId: string, offerId: string) {
    return http.delete(`offers/${offerId}/locations/${locationId}`);
  }

  public getLocations(offerId: string, last?: any) {
    let params: any = {};
    if (last) params = { ...params, start: JSON.stringify(last) };

    return http.get(`offers/${offerId}/locations`, params);
  }

  public async getAllLocations(offerId: string) {
    let response = await this.getLocations(offerId);
    let allLocations = response.data.items;

    while (response.data.last) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.getLocations(offerId, response.data.last);
      allLocations = [...allLocations, ...response.data.items];
    }

    return allLocations;
  }
}
