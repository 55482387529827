const STORAGE_KEY = 'FIDEL_LIVE_MODE_V2';

class LiveService {
  isLive() {
    try {
      const liveSetting = JSON.parse(localStorage.getItem(STORAGE_KEY));
      return typeof liveSetting === 'boolean' ? liveSetting : false;
    } catch (e) {
      return false;
    }
  }

  setLive(live: boolean) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(live));
  }
}

export default new LiveService();
